import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import PiratLogo from '../common/icons/PiratLogo';
import InputWithIcon from './InputWithIcon';
import { text } from '../common/text';

export default function Form(props) {
  const {
    username,
    setUsername,
    email,
    setEmail,
    handleSubmit,
    setShowPopup,
  } = props;
  const [acceptCondition, setAcceptCondition] = useState(false);

  const handleChange = event => {
    setAcceptCondition(!acceptCondition);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <PiratLogo />
      <h1>Din pirat</h1>
      <FormWrapper onSubmit={handleSubmit}>
        <InputWithIcon
          autofocus
          value={username}
          setValue={setUsername}
          icontype="user"
          placeholder="Piratnavn"
          maxLength={20}
          onmaxLength={() => {
            // TODO: Fortæl brugeren at der kun er landkrabber der har så lange navne?
          }}
        />
        <InputWithIcon
          value={email}
          setValue={setEmail}
          icontype="email"
          type="email"
          placeholder="Email (valgfrit)"
          maxLength={50}
        />
        <div
          style={{
            width: '80%',
            fontSize: 12,
            marginBottom: 20,
            opacity: '70%',
            marginTop: 16,
          }}
        >
          {text.brugEmail}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            // alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <CheckboxContainer>
            <input
              type="checkbox"
              name={'cb_accept'}
              checked={acceptCondition}
              onChange={handleChange}
            />
            <span />
          </CheckboxContainer>
          <div>
            <span>{text.betingelseTilAccept}</span>
            <span
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                setShowPopup('PrivacyPolicy');
              }}
            >
              {text.linkTekstPrivatliv}
            </span>
          </div>
        </div>
        <Button
          style={{ width: '100%', marginTop: 20 }}
          type="submit"
          disabled={!username || !acceptCondition}
          cta
        >
          Start spillet
        </Button>
      </FormWrapper>
    </div>
  );
}
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 71px;
  a {
    font-size: 12px;
    margin: 20px 0 10px 0;
    color: #45241c;
    font-weight: 500;
  }
`;

/* https://www.w3schools.com/howto/howto_css_custom_checkbox.asp */
/* Customize the label (the container) */
const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  /* margin-top: 5px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  span {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 18px;
    width: 18px;
    background-color: #FFFBE8;
    border: solid 1px #785F51;
    border-radius: 3px;
    /* Create the checkmark/indicator (hidden when not checked) */
    ::after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ span {
    border: solid 2px #785F51;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ span {
    background-color: #67453D;
    border: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ span::after {
    display: block;
  }

  /* Style the checkmark/indicator */
  span:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 9px;
    border: solid white;

    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(42deg);
    -ms-transform: rotate(42deg);
    transform: rotate(42deg);
  }
`;
