import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import Cross from '../common/icons/Cross';

const Toast = props => {
  const { show, content, color, hide, duration, icon } = props;
  const style = {
    position: 'absolute',
    zIndex: 6,
    left: 10,
    right: 10,
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: color ? color : '#f3edcd',
    boxShadow: '0 2px 15px 0 rgba(69,36,28,0.40)',
  };
  const animation = useSpring({
    top: show ? '10px' : '-100px',
  });
  useEffect(() => {
    if (show) {
      setTimeout(
        () => {
          hide();
        },
        duration ? duration : 6000
      );
    }
  }, [show, duration, hide]);
  const renderIcon = () => {
    if (icon) {
      return <IconContainer>{icon}</IconContainer>;
    }
    return null;
  };
  return (
    <animated.div style={{ ...style, ...animation }}>
      {renderIcon()}
      <ContentContainer>{content}</ContentContainer>
      <CloseButton onClick={() => hide()}><Cross /></CloseButton>
    </animated.div>
  );
};

const IconContainer = styled.div`
  padding-left: 20px;
`;
const ContentContainer = styled.div`
  padding: 20px;
`;
const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: 20px;
`;

export default Toast;
