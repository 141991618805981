import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { useScroll } from 'react-use';
import { getFromDB } from '../../../api/DatabaseCalls.js';
import Trophy from '../../common/icons/Trophy.js';
import PirateIcon from './PirateIcon.js';
import Diamant from '../../common/icons/Diamant.js';
import Modal from '../../common/Modal.js';
import ModalHeader from '../../common/ModalHeader.js';
import ReactGA from 'react-ga';
import { isIos, isInStandaloneMode } from '../../../utility/index.js';

const V_THRESHOLD = 0.3;
export default function Score(props) {
  const { show, onClose, userid } = props;
  const [type, setType] = useState(() => {
    const initialState = 'daily';
    return initialState;
  });
  const [dailyScores, setDailyScores] = useState([]);
  const [alltimeScores, setAlltimeScores] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const dailyScrollRef = React.useRef(null);
  const { y: dailyScrollY } = useScroll(dailyScrollRef);
  const [dailyScrolledToBottom, setDailyScrolledToBottom] = useState(false);
  useEffect(() => {
    if (dailyScrollRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = dailyScrollRef.current;
      setDailyScrolledToBottom(
        clientHeight < 40 || scrollHeight - scrollTop === clientHeight
      );
    }
  }, [dailyScrollY, dailyScores]);

  const alltimeScrollRef = React.useRef(null);
  const { y: alltimeScrollY } = useScroll(alltimeScrollRef);
  const [alltimeScrolledToBottom, setAlltimeScrolledToBottom] = useState(false);
  useEffect(() => {
    if (alltimeScrollRef.current) {
      const {
        scrollHeight,
        scrollTop,
        clientHeight,
      } = alltimeScrollRef.current;
      setAlltimeScrolledToBottom(
        clientHeight < 40 || scrollHeight - scrollTop === clientHeight
      );
    }
  }, [alltimeScrollY, alltimeScores]);


  const bottomScrollShadow = useSpring({
    // marginTop: -8,
    zIndex: 1,
    height: 8,
    boxShadow:
      type === 'daily'
        ? dailyScrolledToBottom
          ? 'rgba(189, 145, 83, 0) 0px -6px 8px -3px'
          : 'rgba(189, 145, 83, 1) 0px -6px 8px -3px'
        : alltimeScrolledToBottom
        ? 'rgba(189, 145, 83, 0) 0px -6px 8px -3px'
        : 'rgba(189, 145, 83, 1) 0px -6px 8px -3px',
  });

  const swipeAnimation = useSpring({
    marginLeft: type === 'daily' ? '0vw' : '-100vw',
  });
  const dailyAnimation = useSpring({
    config: { duration: type === 'daily' ? 400 : 50 },
    ...{ opacity: type === 'daily' ? 1 : 0 },
  });
  const alltimeAnimation = useSpring({
    config: { duration: type === 'alltime' ? 400 : 50 },
    opacity: type === 'alltime' ? 1 : 0,
  });

  const bind = useDrag(({ last, vxvy: [vx, vy] }) => {
    if (last) {
      // getting the swipe direction
      if (Math.abs(vx) > Math.abs(vy)) {
        // swipe left is when horizontal velocity is inferior to minus threshold
        if (vx < -V_THRESHOLD) {
          if (type === 'daily') setType('alltime');
        }
        // swipe right is when horizontal velocity is superior to threshold
        else if (vx > V_THRESHOLD) if (type === 'alltime') setType('daily');
      }
    }
  });
  const firstDotAnimation = useSpring({
    opacity: type === 'daily' ? 1 : 0.5,
  });
  const secondDotAnimation = useSpring({
    opacity: type === 'alltime' ? 1 : 0.5,
  });
  const dotStyle = {
    width: 11,
    height: 11,
    borderRadius: '100%',
    backgroundColor: '#45241C',
  };

  useEffect(() => {
    if (show) {
      getFromDB('score/daily') //Alternativ - /daily -/alltime
        .then(response => response.json())
        .then(data => {
          setDailyScores(data);
          setLoaded(true);
        });
      ReactGA.event({
        category: 'Button',
        action: 'OpenedScore:daily',
      });

      getFromDB('score/alltime') //Alternativ - /daily -/alltime
        .then(response => response.json())
        .then(data => {
          setAlltimeScores(data);
          setLoaded(true);
        });
      ReactGA.event({
        category: 'Button',
        action: 'OpenedScore:alltime',
      });
    }
  }, [show]);

  //This function writes "loading highscore..." instead of "you have 1 placement", if data hasn't loaded
  const renderSubheader = () => {
    if (loaded === true) {
      return (
        <div>
          Du er på{' '}
          <span style={{ fontWeight: 600 }}>
            {type === 'daily'
              ? getPlayerDailyPosition()
              : getPlayerAlltimePosition()}
          </span>
          .-pladsen!
        </div>
      );
    } else {
      return <div>Henter highscore...</div>;
    }
  };

  const getPlayerDailyPosition = () => {
    const myscore = dailyScores.find(s => s.id === userid);
    return myscore ? myscore.placement : dailyScores.length + 1;
  };
  const getPlayerAlltimePosition = () => {
    const myscore = alltimeScores.find(s => s.id === userid);
    return myscore ? myscore.placement : alltimeScores.length + 1;
  };
  const renderDailyScores = () => {
    if (dailyScores.length) {
      const uniquePlacements = new Set();
      return (
        <>
          {dailyScores.map((s, i) => {
            let placement = '';
            if (!uniquePlacements.has(s.placement)) {
              placement = s.placement;
              uniquePlacements.add(s.placement);
            }
            return (
              <React.Fragment key={`${s.id}_${s.score}`}>
                <PositionCell myself={s.id === userid} hidden={i % 2 === 1}>
                  {placement ? placement : '-'}
                </PositionCell>
                <NameCell myself={s.id === userid} odd={i % 2 === 1}>
                  {s.username}
                </NameCell>
                <ScoreCell myself={s.id === userid} odd={i % 2 === 1}>
                  {s.score}
                </ScoreCell>
              </React.Fragment>
            );
          })}
          {dailyScores.length < 10
            ? Array.from(new Array(10 - dailyScores.length)).map((a, i) => {
                const index = dailyScores.length + i;
                return (
                  <React.Fragment key={index}>
                    <PositionCell myself={false} transparent={true}>
                      &nbsp;
                    </PositionCell>
                    <NameCell myself={false} transparent={true}>
                      &nbsp;
                    </NameCell>
                    <ScoreCell myself={false} transparent={true}>
                      &nbsp;
                    </ScoreCell>
                  </React.Fragment>
                );
              })
            : null}
        </>
      );
    }
    return null;
  };
  const renderAlltimeScores = () => {
    if (alltimeScores.length) {
      const uniquePlacements = new Set();
      return (
        <>
          {alltimeScores.map((s, i) => {
            let placement = '';
            if (!uniquePlacements.has(s.placement)) {
              placement = s.placement;
              uniquePlacements.add(s.placement);
            }
            return (
              <React.Fragment key={s.id}>
                <PositionCell myself={s.id === userid} odd={i % 2 === 1}>
                  {placement ? placement : '-'}
                </PositionCell>
                <NameCell myself={s.id === userid} odd={i % 2 === 1}>
                  {s.username}
                </NameCell>
                <ScoreCell myself={s.id === userid} odd={i % 2 === 1}>
                  {s.score}
                </ScoreCell>
              </React.Fragment>
            );
          })}
          {alltimeScores.length < 10
            ? Array.from(new Array(10 - alltimeScores.length)).map((a, i) => {
                const index = alltimeScores.length + i;
                return (
                  <React.Fragment key={index}>
                    <PositionCell myself={false} transparent={true}>
                      &nbsp;
                    </PositionCell>
                    <NameCell myself={false} transparent={true}>
                      &nbsp;
                    </NameCell>
                    <ScoreCell myself={false} transparent={true}>
                      &nbsp;
                    </ScoreCell>
                  </React.Fragment>
                );
              })
            : null}
        </>
      );
    }
    return null;
  };
  const headerIcon = <Trophy height={24} />;
  // Tjek om device er iOS, og ikke i fuldskærm mode
  const iosDevice = isIos() && !isInStandaloneMode();

  return (
    <Modal show={show} onClose={() => onClose('ScoreExit')}>
      <ModalHeader
        icon={headerIcon}
        header={'Highscores' + (type === 'daily' ? ' - I dag' : ' - Altid')}
        subheader={renderSubheader()}
      />
      <animated.div
        {...bind()}
        style={{
          ...{
            width: '200vw',
            display: 'flex',
            alignSelf: 'flex-start',
            marginTop: 20,
          },
          ...swipeAnimation,
        }}
      >
        <animated.div style={dailyAnimation}>
          <Table
            iosDevice={iosDevice}
            style={{
              marginRight: 40,
            }}
            ref={dailyScrollRef}
          >
            <PositionHeaderCell></PositionHeaderCell>
            <NameHeaderCell>
              <PirateIcon />
              <span style={{ marginLeft: 10 }}>Pirater</span>
            </NameHeaderCell>
            <ScoreHeaderCell>
              <Diamant height={16} />
              <span style={{ marginLeft: 10 }}>Score</span>
            </ScoreHeaderCell>
            {renderDailyScores()}
          </Table>
        </animated.div>
        <animated.div style={alltimeAnimation}>
          <Table
            iosDevice={iosDevice}
            style={{
              marginLeft: 40,
            }}
            ref={alltimeScrollRef}
          >
            <PositionHeaderCell></PositionHeaderCell>
            <NameHeaderCell>
              <PirateIcon />
              <span style={{ marginLeft: 10 }}>Pirater</span>
            </NameHeaderCell>
            <ScoreHeaderCell>
              <Diamant height={16} />
              <span style={{ marginLeft: 10 }}>Score</span>
            </ScoreHeaderCell>
            {renderAlltimeScores()}
          </Table>
        </animated.div>
      </animated.div>
      <animated.div style={bottomScrollShadow}></animated.div>
      <div
        {...bind()}
        style={{
          marginTop: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <animated.div
          style={{ ...{ marginRight: 4 }, ...dotStyle, ...firstDotAnimation }}
        ></animated.div>
        <animated.div
          style={{ ...{ marginLeft: 4 }, ...dotStyle, ...secondDotAnimation }}
        ></animated.div>
      </div>
    </Modal>
  );
}

const Table = styled.div`
  width: calc(100vw - 80px);
  display: grid;
  grid-template-columns: 35px 2fr 100px;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  font-size: 13px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  scrollbar-color: transparent transparent;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ${props =>
    props.iosDevice &&
    css`
      max-height: calc(100vh - 325px);
    `};
`;

const PositionHeaderCell = styled.div`
  background-color: #f2ebd0;
  height: 35px;
  position: sticky;
  top: 0;
`;
const NameHeaderCell = styled.div`
  background-color: #f2ebd0;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 35px;
  position: sticky;
  top: 0;
`;
const ScoreHeaderCell = styled.div`
  background-color: #f2ebd0;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 14px;
  height: 35px;
  position: sticky;
  top: 0;
`;
const PositionCell = styled.div`
  background-color: #eee3c6;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  ${props =>
    props.odd &&
    css`
      background-color: #eee3c680;
    `};
  ${props =>
    props.transparent &&
    css`
      background-color: transparent;
    `};
  ${props =>
    props.myself &&
    css`
      background-color: #c2dbca;
    `};
`;
const NameCell = styled.div`
  background-color: #eee3c6;
  display: flex;
  align-items: center;
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  height: 35px;
  ${props =>
    props.odd &&
    css`
      background-color: #eee3c680;
    `};
  ${props =>
    props.transparent &&
    css`
      background-color: transparent;
    `};
  ${props =>
    props.myself &&
    css`
      background-color: #c2dbca;
    `};
`;
const ScoreCell = styled.div`
  background-color: #eee3c6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  ${props =>
    props.odd &&
    css`
      background-color: #eee3c680;
    `};
  ${props =>
    props.transparent &&
    css`
      background-color: transparent;
    `};
  ${props =>
    props.myself &&
    css`
      background-color: #c2dbca;
    `};
`;
