import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../common/Modal';
import Logo from '../../common/icons/Logo';
import Septima from '../../common/icons/Septima';
import Button from './Button';
import { logOut } from '../../../api/auth';
import ReactGA from 'react-ga';
import ReactMarkdown from 'react-markdown'
import { text } from '../../common/text'

export default function Menu(props) {
  const { show, onClose, history, socket } = props;
  const [showMenuItem, setMenuItem] = useState('');
  const [error, setError] = useState('');

  const renderLogoutConfirmation = () => {
    if (showMenuItem === 'logout') {
      return (
        <div>
          <h2>Genstart spil?</h2>
          <p style={{marginBottom: 50}}>{text.genstartSpil}</p>

          <Button
            onClick={() => {
              setMenuItem('');
            }}
            title={'Nej, jeg vil spille videre'}
          />
          <Button
            warning={true}
            // inverted={true}
            onClick={() => {
              logOut()
                .then(() => {
                  socket.disconnect()
                  history.push('/signup');
                })
                .catch(err => setError('Øv! ' + err));
            }}
            title={'Genstart spil'}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderSpilleregler = () => {
    if (showMenuItem === 'spilleregler') {
      return (
        <div>
          <ReactMarkdown source={text.regler}/>
          <Button
            // inverted={true}
            onClick={() => {
              setMenuItem('');
            }}
            title={'Ok'}
          />
        </div>
      );
    } else {
      return null;
    }
  };
  const renderOmByjagt = () => {
    if (showMenuItem === 'ombyjagt') {
      return (
        <div>
          <ReactMarkdown source={text.omByjagt}/>

          <Button
            // inverted={true}
            onClick={() => {
              setMenuItem('');
            }}
            title={'Ok'}
          />
        </div>
      );
    } else {
      return null;
    }
  };
  const renderMenu = () => {
    if (!showMenuItem) {
      if (error) setError('');
      return (
        <ButtonsWrapper>
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Rules'
              })
              setMenuItem('spilleregler');
            }}
            title={'Spilleregler'}
          />
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'About'
              })
              setMenuItem('ombyjagt');
            }}
            title={'Om Byjagt'}
          />
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Logout'
              })
              setMenuItem('logout');
            }}
            title={'Genstart spil'}
          />
        </ButtonsWrapper>
      );
    } else {
      return null;
    }
  };
  const renderError = () => {
    if (error) {
      return <div style={{ color: '#d46161' }}>{error}</div>;
    }
    return null;
  };
  return (
    <Modal show={show} onClose={() => onClose('MenuExit')}>
      <Wrapper>
        <ScrollWrapper>
          <LogoWrapper>
            <Logo height={80} />
          </LogoWrapper>
          {renderMenu()}
          {renderOmByjagt()}
          {renderSpilleregler()}
          {renderLogoutConfirmation()}
          {renderError()}
          <SeptimaWrapper>
            <Septima color={'#67453D'} />
          </SeptimaWrapper>
        </ScrollWrapper>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
`;
const LogoWrapper = styled.div`
  margin-top: 20px;
  height: 80px;
`;
const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 144px);
  overflow-y: auto;
`;
const ButtonsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 50px;
`;

const SeptimaWrapper = styled.div`
  margin-top: auto;
`;
