import React, { useState, useEffect } from 'react';
import JournalItem from './JournalItem';
import { getFromDB } from '../../../api/DatabaseCalls.js';
import styled from 'styled-components';
import Modal from '../../common/Modal';
import ModalHeader from '../../common/ModalHeader';
import Diamant from '../../common/icons/Diamant';
import ReactGA from 'react-ga';
import { text } from '../../common/text'
import ReactMarkdown from 'react-markdown'

export default function Journal(props) {
  const { show, onClose, preOpenJournalItem } = props;
  const [journal, setJournal] = useState([]);
  const [expanded, setExpanded] = useState('');




  useEffect(() => {
    if (show) {
      getFromDB('journal')
        .then(response => response.json())
        .then(data => {
          setJournal(data);
          if (preOpenJournalItem.length > 0) {
            setExpanded(data[data.map(({name}) => name).indexOf(preOpenJournalItem)].question) //Opens the treasure corresponding to the clicked treasure
          }
        });
    }
  }, [show, preOpenJournalItem]);



  useEffect(() => {

    if(expanded !== '')
    {
    ReactGA.event({
      category: 'Button',
      action: 'ExpandedElementInJournal'
    })
    }
}, [expanded]);


  const headerIcon = <Diamant />;
  const subheader = journal.length ? (
    <span>
      <span style={{ fontWeight: 500 }}>
        {journal.filter(svar => svar.correct).length}
      </span>{' '}
      rigtige svar
    </span>
  ) : (
    <span>&nbsp;</span>
  );
  const renderJournal = () => {
    if (journal.length > 0) {
      return (
        <>
          {journal.map(j => {
            const { question } = j;
            return (
              <JournalItem
                key={question}
                expanded={expanded === question}
                setExpanded={setExpanded}
                journalItem={j}
              />
            );
          })}
        </>
      );

    }
    else {
      return (
        <ReactMarkdown source={text.mineSkatte}/>
      );
    }
  };
  return (
    <Modal show={show} onClose={() => onClose('JournalExit')}>
      <ModalHeader
        icon={headerIcon}
        header={'Mine skatte'}
        subheader={subheader}
      />
      <List>{renderJournal()}</List>
    </Modal>
  );
}
const List = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
`;
