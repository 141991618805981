import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
export default function MapItemClicked(props) {
  const { show, titelOfObject, close, icon, textcontent } = props;

  const style = useSpring({
    position: 'absolute',
    zIndex: 4,
    left: 10,
    right: 10,
    height: 115,
    padding: 24,
    top: show ? 10 : -120,
    display: 'flex',
    alignItems: 'stretch',
    backgroundColor: '#F7F3DA',
    boxShadow: '0 2px 15px 0 rgba(69,36,28,0.40)',
  });
  return (
    <animated.div style={style} onClick={close}>
      <IconWrapper>
        {icon}
      </IconWrapper>
      <TextWrapper>
        <h2>{titelOfObject}</h2>
        <div>{textcontent}</div>
      </TextWrapper>
    </animated.div>
  );
}

const IconWrapper = styled.span`
  padding: 18px 20px 0 0;
`;
const TextWrapper = styled.span`
  h2 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 6px;
  }
`;
