import React from 'react';

export default function TreasureDiamond() {
  return (
    <svg
      width="43px"
      height="49px"
      viewBox="0 0 43 49"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Mobile" transform="translate(-29.000000, -21.000000)">
          <g id="skat-diamant" transform="translate(29.000000, 21.000000)">
            <rect
              id="Rectangle"
              fill="#90C79A"
              x="7"
              y="29.3558339"
              width="35"
              height="18.5762088"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#90C79A"
              strokeLinejoin="round"
              x="9.03922547"
              y="13.4849826"
              width="31.0636227"
              height="15.8708513"
            ></rect>
            <rect
              id="Rectangle-Copy"
              stroke="#45241C"
              strokeWidth="1.8"
              strokeLinejoin="round"
              x="21.4712343"
              y="11"
              width="6.19960501"
              height="2.48498259"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#90C79A"
              strokeLinejoin="round"
              x="7"
              y="28.3408553"
              width="3.6"
              height="19.5911874"
            ></rect>
            <path
              d="M10.6,32.4394484 L39.1240341,32.4394484"
              id="Path-6"
              stroke="#45241C"
              strokeWidth="1.8"
            ></path>
            <path
              d="M10.6,47.9320427 L39.1240341,47.9320427"
              id="Path-6"
              stroke="#45241C"
              strokeWidth="1.8"
            ></path>
            <path
              d="M24.5710368,35.234146 C26.1849231,35.234146 27.4979253,36.5471482 27.4979253,38.1610346 C27.4979253,39.0759799 27.0565506,39.9405828 26.3271699,40.4902525 L26.3271699,40.4902525 L26.3271699,42.8440562 L22.8149037,42.8440562 L22.8149037,40.4902525 C22.0855231,39.9411682 21.6441483,39.0759799 21.6441483,38.1610346 C21.6441483,36.5471482 22.9571505,35.234146 24.5710368,35.234146 Z M24.5710368,36.4049015 C23.6028221,36.4049015 22.8149037,37.1928199 22.8149037,38.1610346 C22.8149037,38.786218 23.143886,39.3511075 23.6947264,39.6718944 L23.6947264,39.6718944 L23.9856591,39.8410686 L23.9856591,41.6733008 L25.1564145,41.6733008 L25.1564145,39.8410686 L25.4473472,39.6718944 C25.9981877,39.3511075 26.3271699,38.786218 26.3271699,38.1610346 C26.3271699,37.1928199 25.5392515,36.4049015 24.5710368,36.4049015 Z"
              id="Combined-Shape"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
            <path
              d="M7,47.9314995 L7,42.2524995 C10.1367251,42.2524995 12.6795432,44.7953176 12.6795432,47.9320427 L7,47.9314995 Z"
              id="Path"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#90C79A"
              strokeLinejoin="round"
            ></path>
            <rect
              id="Rectangle-Copy-2"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#90C79A"
              strokeLinejoin="round"
              transform="translate(40.163806, 38.136449) scale(-1, 1) translate(-40.163806, -38.136449) "
              x="38.3638057"
              y="28.3408553"
              width="3.6"
              height="19.5911874"
            ></rect>
            <path
              d="M41.9638057,47.9314995 L41.9638057,42.2524995 C38.8270806,42.2524995 36.2842625,44.7953176 36.2842625,47.9320427 L41.9638057,47.9314995 Z"
              id="Path"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#90C79A"
              strokeLinejoin="round"
            ></path>
            <g
              id="diamant"
              transform="translate(15.262017, 20.044751)"
              stroke="#45241C"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            >
              <polygon
                id="Path-7"
                fill="#F7F3DA"
                points="5.00356709 9.23428791 0.113834749 3.12037314 3.12037314 0 15.6018657 0 18.6745515 3.12037314 13.6154696 9.23428791"
              ></polygon>
              <path
                d="M18.4188789,3.12037314 L0.425860969,3.12037314"
                id="Line"
              ></path>
              <polyline
                id="Path-8"
                points="3.12037314 0 6.24074629 3.12037314 8.12394024 9.23428791"
              ></polyline>
              <polyline
                id="Path-8-Copy"
                transform="translate(13.110408, 4.617144) scale(-1, 1) translate(-13.110408, -4.617144) "
                points="10.6086249 0 13.7289981 3.12037314 15.612192 9.23428791"
              ></polyline>
              <polyline
                id="Path-9"
                points="6.24074629 3.12037314 9.42236993 0 12.4918189 3.12037314"
              ></polyline>
            </g>
            <path
              d="M19.6135418,0.386675092 L21.0313627,0.386675092 L21.0313627,1.80449602 L19.6135418,1.80449602 L19.6135418,0.386675092 Z M18.1957209,1.80449602 L19.6135418,1.80449602 L19.6135418,3.22231696 L18.1957209,3.22231696 L18.1957209,1.80449602 Z M19.6135418,3.22231696 L21.0313627,3.22231696 L21.0313627,4.64013789 L19.6135418,4.64013789 L19.6135418,3.22231696 Z M21.0313627,1.80449602 L22.4491837,1.80449602 L22.4491837,3.22231696 L21.0313627,3.22231696 L21.0313627,1.80449602 Z"
              id="Shape"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
            <path
              d="M37.3860161,4.11491282 L38.803837,4.11491282 L38.803837,5.53273375 L37.3860161,5.53273375 L37.3860161,4.11491282 Z M35.9681951,5.53273375 L37.3860161,5.53273375 L37.3860161,6.95055469 L35.9681951,6.95055469 L35.9681951,5.53273375 Z M37.3860161,6.95055469 L38.803837,6.95055469 L38.803837,8.36837562 L37.3860161,8.36837562 L37.3860161,6.95055469 Z M38.803837,5.53273375 L40.2216579,5.53273375 L40.2216579,6.95055469 L38.803837,6.95055469 L38.803837,5.53273375 Z"
              id="Shape"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
            <path
              d="M1.41782093,13.5 L2.83564186,13.5 L2.83564186,14.9178209 L1.41782093,14.9178209 L1.41782093,13.5 Z M2.27373675e-13,14.9178209 L1.41782093,14.9178209 L1.41782093,16.3356419 L2.27373675e-13,16.3356419 L2.27373675e-13,14.9178209 Z M1.41782093,16.3356419 L2.83564186,16.3356419 L2.83564186,17.7534628 L1.41782093,17.7534628 L1.41782093,16.3356419 Z M2.83564186,14.9178209 L4.25346279,14.9178209 L4.25346279,16.3356419 L2.83564186,16.3356419 L2.83564186,14.9178209 Z"
              id="Shape"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
