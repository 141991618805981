import React, { useState } from 'react';
// import { useSpring, animated } from 'react-spring';
import Logo from '../common/icons/Logo';
import Button from './Button';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { text } from '../common/text'

const V_THRESHOLD = 0.3;
export default function Intro(props) {
  const { setIntroDone } = props;
  const [introPart, setIntroPart] = useState(1);
  const bind = useDrag(({ last, vxvy: [vx, vy] }) => {
    if (last) {
      // getting the swipe direction
      if (Math.abs(vx) > Math.abs(vy)) {
        // swipe left is when horizontal velocity is inferior to minus threshold
        if (vx < -V_THRESHOLD) {
          if (introPart === 1) setIntroPart(2);
        }
        // swipe right is when horizontal velocity is superior to threshold
        else if (vx > V_THRESHOLD) if (introPart === 2) setIntroPart(1);
      }
    }
  });
  const firstPartAnimation = useSpring({
    marginLeft: introPart === 1 ? '0vw' : '-100vw',
  });
  const firstDotAnimation = useSpring({
    opacity: introPart === 1 ? 1 : 0.5,
  });
  const secondDotAnimation = useSpring({
    opacity: introPart === 2 ? 1 : 0.5,
  });
  const dotStyle = {
    width: 11,
    height: 11,
    borderRadius: '100%',
    backgroundColor: '#45241C',
  };
  const AnimatedButton = animated(Button);
  const buttonOneAnimation = useSpring({
    opacity: introPart === 1 ? 1 : 0,
  });
  const buttonTwoAnimation = useSpring({
    width: introPart === 1 ? '45%' : '100%',
    // marginLeft: introPart === 1 ? 8 : 0,
  });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'hidden',
      }}
    >
      <div>
        <Logo height={100}/>
      </div>
      <h2 style={{ marginBottom: 5 }}>Velkommen til</h2>
      <h1 style={{ marginTop: 0, marginBottom: 30 }}>Septimas Byjagt</h1>
      <animated.div
        {...bind()}
        style={{
          ...{ width: '200vw', display: 'flex', alignSelf: 'flex-start', opacity: '80%', lineHeight: '140%' },
          ...firstPartAnimation,
        }}
      >
        <span
          style={{
            width: '100vw',
            marginLeft: -20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
        {text.introPart1}
        </span>
        <span
          style={{
            width: '100vw',
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {text.introPart2}
        </span>
      </animated.div>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
        }}
      >
        <animated.div
          style={{ ...{ marginRight: 4 }, ...dotStyle, ...firstDotAnimation }}
        ></animated.div>
        <animated.div
          style={{ ...{ marginLeft: 4 }, ...dotStyle, ...secondDotAnimation }}
        ></animated.div>
      </div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          marginTop: 30,
          height: 100,
        }}
      >
        <AnimatedButton
          style={{
            ...buttonOneAnimation,
            ...{ position: 'absolute', width: '45%' },
          }}
          onClick={() => setIntroDone(true)}
          disabled={introPart !== 1}
        >
          Spring over
        </AnimatedButton>
        <AnimatedButton
          style={{
            ...buttonTwoAnimation,
            ...{ position: 'absolute', right: 0 },
          }}
          cta
          onClick={() => {
            if (introPart === 1) {
              setIntroPart(2);
            } else {
              setIntroDone(true);
            }
          }}
        >
          <span>{introPart === 1 ? 'Næste' : 'Start'}</span>
        </AnimatedButton>
      </div>
    </div>
  );
}
