import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useScroll } from 'react-use';
import { getFromDB } from '../../../api/DatabaseCalls.js';
import TreasureIcon from './TreasureIcon.js';
import Minimize from '../../common/icons/Minimize';
import Modal from '../../common/Modal.js';
import { text } from '../../common/text';
import ReactMarkdown from 'react-markdown';

export default function Question(props) {
  const { show, treasure, onAnswer, showPopup } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [answerArray, setAnswerArray] = useState([]);
  useEffect(() => {
    if (treasure) {
      getFromDB('treasure/' + treasure.id)
        .then(response => response.json())
        .then(data => {
          setAnswerArray(data);
          setLoading(false);
        })
        .catch(err => {
          setError(text.noConnection);
          setLoading(false);
        });
    }
  }, [show, treasure]);

  const renderLoading = () => {
    if (loading) {
      return <LoadingWrapper>Loading ...</LoadingWrapper>;
    }
    return null;
  };
  const renderQuestion = () => {
    if (error) {
      return (
        <LoadingWrapper>
          <ReactMarkdown source={error} />
        </LoadingWrapper>
      );
    } else if (!loading && answerArray.length) {
      return (
        <>
          <h2>{answerArray[0].question}</h2>
          {answerArray.map((a, i) => {
            return (
              <AnswerButton
                key={i}
                onClick={() => {
                  onAnswer(a.id);
                }}
              >
                {a.text}
              </AnswerButton>
            );
          })}
        </>
      );
    }
    return null;
  };
  const minimizeIcon = <Minimize width={20} />;

  const scrollRef = React.useRef(null);
  const { y: scrollY } = useScroll(scrollRef);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  useEffect(() => {
    if (scrollRef.current && !loading) {
      const { scrollHeight, scrollTop, clientHeight } = scrollRef.current;
      setScrolledToBottom(
        scrollHeight - scrollTop === clientHeight
      );
    }
  }, [scrollY,loading]);
  const scrollShadow = useSpring({
    zIndex: 1,
    width: '100%',
    maxWidth: 260,
    height: 12,
    boxShadow: scrolledToBottom
      ? 'rgba(189, 145, 83, 0) 0px -6px 8px -3px'
      : 'rgba(189, 145, 83, 1) 0px -6px 8px -3px',
  });

  return (
    <Modal
      closeIcon={minimizeIcon}
      show={show}
      onClose={() => showPopup('QuestionExit')}
      closed={() => {
        setError('');
        setLoading(true);
      }}
    >
      <Wrapper>
        <IconWrapper>
          <TreasureIcon />
        </IconWrapper>
        <QuestionWrapper ref={scrollRef}>
          {renderLoading()}
          {renderQuestion()}
        </QuestionWrapper>
        <animated.div style={scrollShadow}></animated.div>
      </Wrapper>
    </Modal>
  );
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const IconWrapper = styled.div`
  background-color: #eee3c6;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;
const LoadingWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const QuestionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 260px;
  margin-top: 10px;
  h2 {
    font-size: 16px;
  }
  max-height: calc(100vh - 260px);
  overflow-y: auto;
`;
const AnswerButton = styled.button`
  cursor: pointer;
  min-height: 47px;
  width: 100%;
  background-color: #ee984180;
  border: none;
  outline: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  margin-bottom: 12px;
  transition: background-color 0.3s;
  :hover {
    background-color: #ee9841;
  }
`;
