import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import { useSpring, animated } from 'react-spring';
import { isAuthenticated, signUp } from '../../api/auth';
import Spinner from '../common/Spinner';
// import { isIos, isInStandaloneMode } from './util';
// import PopupInstallOnIos from './PopupInstallOnIos';
import Intro from './Intro';
import Form from './Form';

import InfoBox from './InfoBox';
import Email from '../common/icons/Email';
import Septima from '../common/icons/Septima';
import { text } from '../common/text'
import ReactMarkdown from 'react-markdown'

//This is the function for adding a new user to the database
const SignUp = props => {
  const { history } = props;
  const [username, setUsername] = useState('');
  const [showPopup, setShowPopup] = useState('');
  const [introDone, setIntroDone] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    isAuthenticated().then(response => {
      if (response.ok) {
        history.push('/map');
      } else {
        setLoading(false);
      }
    });
  }, [history]);

  const handleSubmit = event => {
    // use the server endpoint
    event.preventDefault();
    signUp(username, email)
      .then(res => {
        if (res.ok) {
          setLoading(false);
          history.push('/map');
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch(err => {
        setLoading(false);
        // TODO: HAndle errors
      });
  };
  // const renderInstallPopup = () => {
  //   if (isIos() && !isInStandaloneMode()) {
  //     return <PopupInstallOnIos />;
  //   }
  // };
  if (loading) {
    return (
      <Wrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner size={100} />
          <span>Loading ...</span>
        </div>
      </Wrapper>
    );
  }
  const renderIntro = () => {
    if (!loading && !introDone) {
      return <Intro setIntroDone={setIntroDone} />;
    }
    return null;
  };
  const renderForm = () => {
    if (!loading && introDone) {
      return (
        <Form
          username={username}
          setUsername={setUsername}
          email={email}
          setEmail={setEmail}
          handleSubmit={handleSubmit}
          setShowPopup={setShowPopup}
        />
      );
    }
    return null;
  };
  return (
    <Wrapper>
      {renderIntro()}
      {renderForm()}
      <LogoWrapper>
        <Septima />
      </LogoWrapper>
      {/* {renderInstallPopup()} */}
      <InfoBox
        show={showPopup === 'PrivacyPolicy'}
        showPopup={setShowPopup}
        icon={<Email />}
        text={<div>{<ReactMarkdown source={text.privatliv}/>}</div>}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const LogoWrapper = styled.div`
  margin-top: auto;
  align-self: center;
`;
export default SignUp;
