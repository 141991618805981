const baseurl = process.env.REACT_APP_SERVER_URL;
//Searches an array for a value and returns the index of that value
export function whereInArray(value, parameter, array) {
  var result = -1; //By default the answer is no
  for (var i = 0; i < array.length; i++) {
    // compare coordinates using Leaflet's equals function:
    // console.log(array[i].parameter)
    if (array[i][parameter] === value) {
      //Ret til .parameter, når det virker
      result = i;
      return result;
    }
  }
  // console.log("I did not find that")
  return result;
}

//This function removes a single value from an array. The commented stuff in there is because I remember earlier having issue withremoving stuff from arrays. Last time i needed to do it the now commented way. I keep it here incase issue appear further down the line
export function removeFromArray(array, index) {
  // var tempArray = array.concat();
  // tempArray.splice(index, 1)
  // console.log(tempArray)

  array.splice(index, 1);
  // console.log(array)
  return array;
}

//These three functions below are currently not functional and not in use //////////////////////////////////////

// export function getStateFromDB(route, state){
//     let self = this //A workaround for 'setState' of undefined
//     fetch('http://localhost:4001/' + route)
//     .then(function(response) {
//   return response.json();
// })
// .then(function(myJson) {
//   self.setState({
//     state: myJson
//   })
// })
// .catch(err => console.log(err))
// }

export function getStateFromDB(route) {
  return fetch(`${baseurl}/route`);
}

export function getStateByIdFromDB(route, state, parameter) {
  let self = this; //A workaround for 'setState' of undefined

  fetch(`${baseurl}/route`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: parameter, //Here the index of a nearby treasure is used to get the correct question
    }),
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(myJson) {
      self.setState({
        state: myJson,
      });
    })
    .catch(err => console.log(err));
}
