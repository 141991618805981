import React from 'react';
import styled from 'styled-components';
import Hamburger from '../common/icons/Hamburger';

export default function MenuButton(props) {
  const { onClick } = props;
  return (
    <Button onClick={onClick}>
      <Hamburger />
    </Button>
  );
}
const Button = styled.button`
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: #faf6e0;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
  box-shadow: 0 0 6px 0 rgba(69, 36, 28, 0.2);
  padding: 0;
`;
