import React, { Component } from 'react';
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Alert,
} from 'react-bootstrap';
import '../styles.css';
import { isAuthenticated } from '../api/auth';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '123',
      hasError: false,
      error: undefined,
      loading: true,
    };
  }
  async componentDidMount() {
    const { history } = this.props;
    try {
      let response = await isAuthenticated();
      let isLoggedIn = response.ok;
      if (isLoggedIn) {
        history.push('/map');
      } else {
        throw new Error(response.statusText);
      }
      this.setState({
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  }
  validateForm() {
    // TODO: Make a better validation
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = event => {
    // use the server endpoint
    const { history } = this.props;
    event.preventDefault();
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Important, sends the session cookie!
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
      }),
    })
      .then(res => {
        console.log('Then');
        if (res.ok) {
          this.setState({
            loading: false,
            hasError: false,
            isAuthenticated: true,
          });
          history.push('/map');
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          hasError: true,
          error: err,
          loading: false,
        });
      });
  };

  render() {
    return this.state.loading ? (
      <div className="loading"></div>
    ) : (
      <div className="Login">
        <h2>Septima - Byjagt</h2>
        <form onSubmit={this.handleSubmit}>
          {this.state.hasError && (
            <Alert
              variant="danger"
              onClose={() => this.setState({ hasError: false })}
              dismissible
            >
              {this.state.error.message}
            </Alert>
          )}
          <FormGroup controlId="username">
            <FormLabel>Username</FormLabel>
            <FormControl
              autoFocus
              type="username"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          {/*<FormGroup controlId="password">
            <FormLabel>Password</FormLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
                    */}
          <Button block disabled={!this.validateForm()} type="submit">
            Login
          </Button>
          <Button
            size="lg"
            block
            onClick={() => {
              const { history } = this.props;
              history.push('/signup');
            }}
          >
            Signup
          </Button>
        </form>
      </div>
    );
  }
}
export default Login;
