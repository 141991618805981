import React from 'react';

export default function Diamant(props) {
  const { width, height, fillColor } = props;
  return (
    <svg
      width={width ? width : height ? height * (23 / 18) : 23}
      height={height ? height : width ? width * (18 / 23) : 18}
      viewBox="0 0 23 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill={fillColor ? fillColor : 'none'}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Bottom-bar"
          transform="translate(-25.000000, -25.000000)"
          stroke="#45241C"
        >
          <g id="diamant" transform="translate(25.541381, 25.500000)">
            <polygon
              id="Path-7"
              points="0.129012715 3.5364229 3.5364229 0 17.6821145 0 21.1644917 3.5364229 10.6151203 16.5958222"
            ></polygon>
            <path
              d="M20.8747294,3.5364229 L0.482642432,3.5364229"
              id="Line"
            ></path>
            <polyline
              id="Path-8"
              points="3.5364229 0 7.07284579 3.5364229 10.6151203 16.0810654"
            ></polyline>
            <polyline
              id="Path-8-Copy"
              transform="translate(14.154469, 8.040533) scale(-1, 1) translate(-14.154469, -8.040533) "
              points="10.6151203 0 14.1515431 3.5364229 17.6938176 16.0810654"
            ></polyline>
            <polyline
              id="Path-9"
              points="7.07284579 3.5364229 10.6786859 0 14.1573947 3.5364229"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
}
