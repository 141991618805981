import React from 'react';

export default function Trophy(props) {
  const { height, width } = props;
  return (
    <svg
      width={width ? width : height ? height * (20 / 21) : 20}
      height={height ? height : width ? width * (21 / 20) : 21}
      viewBox="0 0 20 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Bottom-bar" transform="translate(-221.000000, -23.000000)">
          <g id="Pokal" transform="translate(221.541381, 24.000000)">
            <polygon
              id="Rectangle"
              stroke="#45241C"
              strokeLinejoin="round"
              points="0 1.55924177 5 1.55924177 5 7.55924177 1.61983208 7.55924177"
            ></polygon>
            <polygon
              id="Rectangle-Copy"
              stroke="#45241C"
              strokeLinejoin="round"
              transform="translate(16.449083, 4.559242) scale(-1, 1) translate(-16.449083, -4.559242) "
              points="13.9490833 1.55924177 18.9490833 1.55924177 18.9490833 7.55924177 15.5689154 7.55924177"
            ></polygon>
            <path
              d="M2.94908332,0 L15.9490833,0 C15.9490833,7.24022658 13.7824167,10.9063304 9.44908332,10.9983115 C5.11574998,11.0902926 2.94908332,7.4241888 2.94908332,0 Z"
              id="Rectangle"
              stroke="#45241C"
              fill="#FAF6E0"
              strokeLinejoin="round"
            ></path>
            <rect
              id="Rectangle"
              stroke="#45241C"
              strokeLinejoin="round"
              x="8.45861923"
              y="11"
              width="2"
              height="3"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#45241C"
              strokeLinejoin="round"
              x="6.45861923"
              y="14"
              width="6"
              height="2.52815782"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#45241C"
              strokeLinejoin="round"
              x="3.95861923"
              y="16.5281578"
              width="11.0318449"
              height="2.52815782"
            ></rect>
            <path
              d="M10.2245417,5.30924177 L10.2245417,3.80924177 L8.72454166,3.80924177 L8.72454166,5.30924177 L10.2245417,5.30924177 Z M10.2245417,5.30924177 L10.2245417,6.80924177 L8.72454166,6.80924177 L8.72454166,5.30924177 L7.22454166,5.30924177 L7.22454166,3.80924177 L8.72354166,3.80824177 L8.72454166,2.30924177 L10.2245417,2.30924177 L10.2245417,3.80924177 L11.7245417,3.80924177 L11.7245417,5.30924177 L10.2245417,5.30924177 Z"
              id="Combined-Shape"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
