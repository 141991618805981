import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './Router';

// Setting up Google Analytics
import ReactGA from 'react-ga';
const trackingId = "UA-37344804-23";
ReactGA.initialize(trackingId);


ReactDOM.render(<Router />, document.getElementById('root'));

console.log(
  `%c
                            _.--.
                        _.-'_:-'||
                    _.-'_.-::::'||
               _.-:'_.-::::::'  ||
             .'\`-.-:::::::'     ||
            /.'\`;|:::::::'      ||_
           ||   ||::::::'     _.;._'-._
           ||   ||:::::'  _.-!oo @.!-._'-.
           \\'.  ||:::::.-!()oo @!()@.-'_.|
            '.'-;|:.-'.&$@.& ()$%-'o.'\\U||
              \`>'-.!@%()@'@_%-'_.-o _.|'||
               ||-._'-.@.-'_.-' _.-o  |'||
               ||=[ '-._.-\\U/.-'    o |'||
               || '-.]=|| |'|      o  |'||
               ||      || |'|        _| ';
               ||      || |'|    _.-'_.-'
               |'-._   || |'|_.-'_.-'
            jgs '-._'-.|| |' \`_.-'
                    '-.||_/.-'

`,
  'color: #935116; font-family:monospace'
);
const logCss = "color: #935116; font-size: medium; font-family:'Pirata One', cursive;";
console.log(`%cOhøj fremmede!`, logCss);
console.log(`%cSplitte mine bramsejl du er en nysgerrig pirat. Nysgerrighed er godt, og DIN nysgerrighed har bragt dig til det ægte guld: Kildekoden. I Septima er vi også meget nysgerrige - vi elsker at opdage nyt land og sætte nye skibe i søen bygget med den sidste nye teknologi.`, logCss);
console.log(`%cEr du nysgerrig efter, hvad Septima kan tilbyde eller er du interesseret i job eller praktik, så findes der mere guld på www.septima.dk`, logCss);
console.log(`%cByjagten er bygget af Andreas Risgaard (vores seje praktikant), Ida, Nicolai, John og Asger.`, logCss);
console.log(`%cVi ses på de syv have - yarrrrrh!`, logCss);