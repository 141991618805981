const baseurl = process.env.REACT_APP_SERVER_URL;

export function isAuthenticated() {
  return fetch(`${baseurl}/auth`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include', // Important, sends the session cookie!
  });
}

export function signUp(username, email) {
  return fetch(`${baseurl}/signup`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      username: username,
      email: email,
    }),
  });
}

//TODO: Make it work
export function logOut() {
  return fetch(`${baseurl}/logout`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(),
  });
}
