import React from 'react';

export default function Cross() {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mobile"
          transform="translate(-324.000000, -31.000000)"
          stroke="#A29180"
          strokeWidth="3"
        >
          <g id="Group" transform="translate(325.000000, 32.500000)">
            <path d="M17.234146,0 L0.45419155,16.7799545" id="Path-15"></path>
            <path
              d="M17.234146,0 L0.45419155,16.7799545"
              id="Path-15-Copy"
              transform="translate(9.000000, 8.500000) scale(-1, 1) translate(-9.000000, -8.500000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
