import React from 'react';

export default function ChevronDown() {
  return (
    <svg
      width="14px"
      height="10px"
      viewBox="0 0 14 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.400227865"
      >
        <g
          id="Mobile"
          transform="translate(-311.000000, -150.000000)"
          stroke="#45241C"
          strokeWidth="1.5"
        >
          <polyline
            id="Path"
            transform="translate(317.800000, 154.832508) rotate(-270.000000) translate(-317.800000, -154.832508) "
            points="314.362105 148.832508 321.237895 154.832508 314.362105 160.832508"
          ></polyline>
        </g>
      </g>
    </svg>
  );
}
