import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import TreasureIcon from './TreasureIcon';
import { text } from '../../common/text'
import ReactMarkdown from 'react-markdown'

export default function TreasureFound(props) {
  const { onYesClick, show } = props;
  const style = useSpring({
    position: 'absolute',
    zIndex: 4,
    left: 10,
    right: 10,
    height: 92,
    top: show ? 10 : -120,
    display: 'flex',
    alignItems: 'stretch',
    backgroundColor: '#F7F3DA',
    boxShadow: '0 2px 15px 0 rgba(69,36,28,0.40)',
  });
  return (
    <animated.div style={style}>
      <IconWrapper>
        <TreasureIcon />
      </IconWrapper>
      <TextWrapper>
      <ReactMarkdown source={text.openTreasure}/>
      </TextWrapper>
      <Button onClick={() => onYesClick('Question')}>Ja</Button>
    </animated.div>
  );
}

const IconWrapper = styled.span`
  padding: 31px 20px;
`;
const TextWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Button = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  margin-left: auto;
  width: 92px;
  border: none;
  border-left: solid 1px #45241c21;
`;
