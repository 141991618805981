import React from 'react';
import styled from 'styled-components';
import Modal from '../common/Modal.js';

export default function InfoBox(props) {
  const { show, showPopup, icon, text } = props;

  return (
    <Modal
      show={show}
      onClose={() => showPopup('hidden')}
      slideInFrom={'top'}
      style={{ bottom: 10 }}
    >
      <Wrapper>
        <IconWrapper>{icon}</IconWrapper>
        <div className="verticalSpaceSmall" />
        {text}
      </Wrapper>
    </Modal>
  );
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
`;
const IconWrapper = styled.div`
  margin-top: 20px;
  background-color: #eee3c6;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;
