import React from 'react';

export default function Minimize(props) {
  const { width, height, color } = props;
  return (
    <svg
      width={width ? width : height ? height : 42}
      height={height ? height : width ? width : 42}
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Minimize"
          transform="translate(-646.000000, -60.000000)"
          fill={color ? color : '#A29180'}
          fillRule="nonzero"
        >
          <g transform="translate(646.000000, 60.000000)">
            <path
              d="M4,38 L38,38 L38,4 L4,4 L4,38 Z M2,0 L40,0 C41.1045695,0 42,0.8954305 42,2 L42,40 C42,41.1045695 41.1045695,42 40,42 L2,42 C0.8954305,42 0,41.1045695 0,40 L0,2 C0,0.8954305 0.8954305,0 2,0 Z"
              id="Rectangle"
            ></path>
            <polygon id="Line" points="8 34 8 28 34 28 34 34"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
}
