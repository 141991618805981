import React, { Component } from 'react';

//This is the function for adding a new user to the database
class Logout extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    fetch(`${process.env.REACT_APP_SERVER_URL}/logout`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Important, sends the session cookie!
      body: JSON.stringify({}),
    })
      .then(this.props.history.push('/signup')) //This changes to the route with the map
      .catch(err => console.log(err));
  }
  render() {
    return <div></div>;
  }
}
export default Logout;
