import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './utility/PrivateRoute';
import MapContainer from './components/Map/MapContainer';
import Login from './components/Login';
import Logout from './components/Logout';
import Signup from './components/SignUp/Signup';

class AppRouter extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/signup" component={Signup} />
            <PrivateRoute path="/map" component={MapContainer} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default AppRouter;
