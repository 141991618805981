// Her er alle tekststykker i appen
// Længere tekststykker er sat op med Markdown, mens enkelte sætning bare forsat er html

export const text = {
  //SignUp
  introPart1: `
  Rundt omkring i hele København er der blevet placeret digitale skatte.
  For at samle en skat op skal du gå hen til den. Når du kommer tæt nok på, kan du prøve
  at åbne den ved at svare på et spørgsmål. Du får kun ét
  forsøg til hver skat, så svar med omhu!`,
  introPart2: `Du kan altid vende tilbage og fortsætte jagten på et andet tidspunkt -
            også selvom du lukker din browser ned. Dog kan du kun tilgå din bruger
            fra denne browser.`,
  betingelseTilAccept: `Jeg er fyldt 16 år og accepterer `,
  linkTekstPrivatliv: `Septimas privatlivspolitik`,
  privatliv: `
  # Privatlivspolitik

  Oplysninger om vores behandling af dine personoplysninger mv.

  ### Vi er den dataansvarlige – hvordan kontakter du os?

  Septima P/S er dataansvarlig for behandlingen af de personoplysninger, som vi har modtaget om dig. Du finder vores kontaktoplysninger nedenfor.

  Septima P/S
  Frederiksberggade 19, 2. sal
  1459 København K

  CVR-nr.: 34900841  
  Telefon: 72300672  
  E-mailadresse: kontakt@septima.dk  

  ### Når du bruger applikationen, indsamler vi følgende personoplysninger:

  **Spillerens placering, når appen er åben**  
  Dette bruges til at se hvorvidt spilleren er tæt på en skat. Din placering vil også være synlig for andre spillere. Det bruges derudover også til at se om nogen snyder.

  **Email**  
  Dette bruges til at kontakte vinderen af skattejagten samt tilmeldes Septimas nyhedsbrev.


  **Tekniske oplysninger om enheden og internettet**  
  Herunder operativsystemet, browserversion, internet udbyder og IP-adresser. Denne information kan knyttes til din profil. Oplysningerne giver os blandt andet mulighed for at optimere af applikationen til den enhed, som du bruger. Disse bliver indsamlet af Google Analytics. Ønsker du ikke at afgive disse kan du installere udvidelsen [Gaoptout](https://tools.google.com/dlpage/gaoptout).

  ### Opbevaring af dine personoplysninger

  Dine personoplysninger gemmes i indtil 14 måneder. Dette er for at din progression i spillet ikke slettes, mens at spillet stadig er aktuelt.

  ### Tredjeparters adgang til dine oplysninger

  Spillernes placeringer vil i pseudonymiseret form blive benyttet til bl.a. visualisering i Septimas praktikants, A Riisgaard, akademiske rapport "Byjagt".

  ### Dine rettigheder

  Du har efter databeskyttelsesforordningen en række rettigheder i forhold til vores behandling af oplysninger om dig.

  Hvis du vil gøre brug af dine rettigheder skal du kontakte os. Vi kræver, at du kan dokumentere din identitet.

  **Ret til at se oplysninger (indsigtsret)**  
  Du har ret til at få indsigt i de oplysninger, som vi behandler om dig, samt en række yderligere oplysninger.

  **Ret til berigtigelse (rettelse)**  
  Du har ret til at få urigtige oplysninger om dig selv rettet.

  **Ret til sletning**  
  I særlige tilfælde har du ret til at få slettet oplysninger om dig, inden tidspunktet for vores almindelige generelle sletning indtræffer.

  **Ret til begrænsning af behandling**  
  Du har visse tilfælde ret til at få behandlingen af dine personoplysninger begrænset. Hvis du har ret til at få begrænset behandlingen, må vi fremover kun behandle oplysningerne – bortset fra opbevaring – med dit samtykke, eller med henblik på at retskrav kan fastlægges, gøres gældende eller forsvares eller for at beskytte en person eller vigtige samfundsinteresser.

  **Ret til indsigelse**  
  Du har i visse tilfælde ret til at gøre indsigelse mod vores eller lovlige behandling af dine personoplysninger. Du kan også gøre indsigelse mod behandling af dine oplysninger til direkte markedsføring.

  **Ret til at transmittere oplysninger (dataportabilitet)**  
  Du har i visse tilfælde ret til at modtage dine personoplysninger i et struktureret, almindeligt anvendt og maskinlæsbart format samt at få overført disse personoplysninger fra én dataansvarlig til en anden uden hindring.

  Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de registreredes rettigheder, som du finder på [www.datatilsynet.dk](https://www.datatilsynet.dk).

  **Klage til Datatilsynet**  
  Du har ret til at indgive en klage til Datatilsynet, hvis du er utilfreds med den måde, vi behandler dine personoplysninger på. Du finder Datatilsynets kontaktoplysninger på [www.datatilsynet.dk](https://www.datatilsynet.dk).



  # Cookies

  Der bliver anvendt cookies til trafikmåling samt at holde styr på, hvem der er logget ind.

  ### Så lang tid opbevares cookies
  
  Cookies sletter sig selv efter et vist antal måneder - de fornyes efter hvert besøg.

  ### Sådan sletter du cookies

  Ønsker du at slette de cookies, som vores hjemmesider har lagt på din telefon, kan du læse her, hvordan du gør: [minecookies.org/cookiehandtering](minecookies.org/cookiehandtering). Vær dog opmærksom på at mister din progression i spillet, hvis du sletter dine cookies.


  ### Sådan sletter du cookies

  Hvis du ikke ønsker at modtage cookies, kan du blokere dem helt, også det kan du læse mere om her: [minecookies.org/cookiehandtering](minecookies.org/cookiehandtering).

  `,
  brugEmail: `Mailen bruges til uddeling af præmier og tilmeldes Septimas nyhedsbrev`,

  //Menu
  regler: `
  ## Spilleregler

  For at samle en skat op skal du gå hen til den. Når du kommer tæt nok på kan du prøve
  at åbne den ved at svare på et spørgsmål. Du får kun ét forsøg til hver skat, så svar 
  med omhu!

  Du kan altid vende tilbage og fortsætte jagten på et andet tidspunkt -
  også selvom du lukker din browser ned. Dog kan du kun tilgå din bruger
  fra denne browser.
  `,
  omByjagt: `
## Om byjagten

  Byjagt er et spil lavet af Septima sammen med vores seje praktikant Andreas
  Riisgaard. I Septima er vi eksperter i at arbejde med data, som har en geografisk 
  dimension. 

  Vi ses - Yarrrrrrh!
  `,
  genstartSpil: `Dette afslutter spillet, og du vil miste din fremgang.`,

  //MapContainer
  mineSkatte: `Her kan du se alle de skatte du har samlet sammen.`,

  toastSucces: `Ohøj! Det er korrekt!`,
  toastFail: `Splitte mine bramsejl! Det er forkert!`,
  toastAllTreasure: `Ohøj kaptajn, du har fundet alle skattene!`,
  playerClicked: `Her er der en anden pirat`,
  treasureClicked: `For at få fingre i skatten skal du gå hen til den.`,
  noGPS: `**Byjagt har ikke adgang til din placering**

  Spillet er nødt til at have adgang til din placering, da det ellers ikke ved, hvornår du er tæt på en skat.

  Er du sikker på at din gps er slået til, og at din browser har tilladelse til at bruge den?`,


  //FoundTreasure
  openTreasure: `Du har fundet en skat!  
  **Vil du åbne den?**`,
  noConnection: `Du har ikke nogen internetforbindelse.

  Prøv at åben skatten igen, når du igen har forbindelse.`



};
