const baseurl = process.env.REACT_APP_SERVER_URL;
export function getFromDB(route) {
  return fetch(`${baseurl}/${route}`, {
    method: 'get',
    credentials: 'include', // Important, sends the session cookie!
  });
}

export function getStateByIdFromDB(route, parameter) {
  return fetch(`${baseurl}/${route}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: parameter, //Here the index of a nearby treasure is used to get the correct question
    }),
  });
}

// export function treasuresNotFound(username){
//
//   fetch('http://localhost:4001/treasure/' + user, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       username: this.state.brugernavn
//     })
//   })
//
// }

//
// export function   getStateByIdFromDB(route, state, parameter){
//     let self = this //A workaround for 'setState' of undefined
//
//     fetch('http://localhost:4001/' + route, {
//       method: 'post',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         id: parameter //Here the index of a nearby treasure is used to get the correct question
//       })
//     })
//     .then(function(response) {
//   return response.json();
//   })
//   .then(function(myJson) {
//     console.log(myJson)
//   self.setState({
//     state: myJson
//   })
//   })
//   .catch(err => console.log(err))
//
//
//   }
