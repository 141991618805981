import React from 'react';
import styled from 'styled-components';
import Modal from '../common/Modal';
import Logo from '../common/icons/Logo';
import Septima from '../common/icons/Septima';
import ReactMarkdown from 'react-markdown'
import { text } from '../common/text'

export default function NoConnection(props) {
  const { show, onClose } = props;

  return (
    <Modal show={show} onClose={() => onClose('NoConnectionExit')}>
      <Wrapper>
        <ScrollWrapper>
          <LogoWrapper>
            <Logo height={80} />
          </LogoWrapper>
          <ReactMarkdown source={text.noGPS}/>
          <SeptimaWrapper>
            <Septima color={'#67453D'} />
          </SeptimaWrapper>
        </ScrollWrapper>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
`;
const LogoWrapper = styled.div`
  margin-top: 20px;
  height: 80px;
`;
const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 144px);
  overflow-y: auto;
`;
const SeptimaWrapper = styled.div`
  margin-top: auto;
`;
