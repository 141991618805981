import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Spinner from '../components/common/Spinner';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isAuthenticated: false,
    };
  }

  async componentDidMount() {
    let response = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Important, sends the session cookie!
    });
    let json = await response.json();
    let username = json.username;
    let userid = json.userid;

    let isAuth = response.ok;

    this.setState({
      loading: false,
      isAuthenticated: isAuth,
      username: isAuth ? username : '',
      userid: isAuth ? userid : '',
    });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          this.state.isAuthenticated ? (
            <Component
              {...props}
              username={this.state.username}
              userid={this.state.userid}
            />
          ) : this.state.loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              <Spinner size={100} />
              <span>Loading ...</span>
            </div>
          ) : (
            <Redirect
              to={{
                pathname: '/signup',
                state: {
                  from: this.props.location,
                  username: this.state.username,
                },
              }}
            />
          )
        }
      />
    );
  }
}
export default PrivateRoute;
