import React from 'react';

export default function Poop() {
  return (
    <svg
      width="21px"
      height="18px"
      viewBox="0 0 21 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mobile"
          transform="translate(-54.000000, -145.000000)"
          fillRule="nonzero"
        >
          <g id="Lort" transform="translate(54.500000, 145.791873)">
            <path
              d="M17.5613758,10.5418671 C17.6614557,10.283014 17.7224065,10.003844 17.7224065,9.70962436 C17.7224065,8.43529963 16.685113,7.39800612 15.4107883,7.39800612 L15.3483324,7.39800612 C15.1432816,5.99952226 14.3968216,4.71127668 13.2526759,3.85269707 L13.1762992,3.79588481 C12.5468498,3.32332744 12.0821934,2.67845221 11.8323701,1.93086341 L11.1889998,0 L7.45406585,2.66754128 C5.8922938,3.78271648 4.88735697,5.51266772 4.67026645,7.39800612 L4.62323648,7.39800612 C3.34891175,7.39800612 2.31161824,8.43529963 2.31161824,9.70962436 C2.31161824,10.003844 2.37256906,10.283014 2.47264894,10.5418671 C1.06400657,10.8255521 5.86197757e-14,12.0712825 5.86197757e-14,13.5623214 C5.86197757e-14,15.2621736 1.38230552,16.6444791 3.08215765,16.6444791 L16.9518671,16.6444791 C18.6517192,16.6444791 20.0340247,15.2621736 20.0340247,13.5623214 C20.0340247,12.0712825 18.9700182,10.8255521 17.5613758,10.5418671 L17.5613758,10.5418671 Z"
              id="Path"
              fill="#45241C"
            ></path>
            <path
              d="M16.9518671,15.1034003 L3.08215765,15.1034003 C2.23223159,15.1034003 1.54107883,14.4122475 1.54107883,13.5623214 C1.54107883,12.7123954 2.23223159,12.0212426 3.08215765,12.0212426 L12.3286306,12.0212426 L12.3286306,10.4801638 L4.62323648,10.4801638 C4.19883782,10.4801638 3.85269707,10.1347755 3.85269707,9.70962436 C3.85269707,9.28447327 4.19883782,8.93908495 4.62323648,8.93908495 L8.47593355,8.93908495 L8.47593355,7.39800612 L6.22827605,7.39800612 C6.43633681,6.01193815 7.19295537,4.74814814 8.34951692,3.92117278 L10.3868563,2.4666291 C10.7397693,3.49225924 11.3831396,4.37679929 12.2522539,5.02844698 L12.3286306,5.08638788 C13.0844966,5.6533815 13.5969355,6.48487174 13.7858079,7.39800612 L11.5580912,7.39800612 L11.5580912,8.93908495 L15.4107883,8.93908495 C15.8351869,8.93908495 16.1813277,9.28447327 16.1813277,9.70962436 C16.1813277,10.1347755 15.8351869,10.4801638 15.4107883,10.4801638 L13.8697094,10.4801638 L13.8697094,12.0212426 L16.9518671,12.0212426 C17.8017932,12.0212426 18.4929459,12.7123954 18.4929459,13.5623214 C18.4929459,14.4122475 17.8017932,15.1034003 16.9518671,15.1034003 Z"
              id="Path"
              stroke="#D29687"
              strokeWidth="0.8"
              fill="#EB9595"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
