import React from 'react';

export default function TreasureIcon(props) {
  const { fill } = props;
  return (
    <svg
      width="37px"
      height="31px"
      viewBox="0 0 37 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Mobile" transform="translate(-35.000000, -40.000000)">
          <g id="skat-default" transform="translate(35.000000, 41.500000)">
            <rect
              id="Rectangle"
              fill={fill ? fill : '#EE9841'}
              x="0.95263866"
              y="8.83504061"
              width="35"
              height="19"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#45241C"
              strokeWidth="1.8"
              fill={fill ? fill : '#EE9841'}
              strokeLinejoin="round"
              x="3.03922547"
              y="0.238150583"
              width="31.0636227"
              height="9.11768331"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#45241C"
              strokeWidth="1.8"
              fill={fill ? fill : '#EE9841'}
              strokeLinejoin="round"
              x="1"
              y="8.3408553"
              width="3.6"
              height="19.5911874"
            ></rect>
            <path
              d="M4.6,12.4394484 L33.1240341,12.4394484"
              id="Path-6"
              stroke="#45241C"
              strokeWidth="1.8"
              fill={fill ? fill : '#EE9841'}
            ></path>
            <path
              d="M4.6,27.9320427 L33.1240341,27.9320427"
              id="Path-6"
              stroke="#45241C"
              strokeWidth="1.8"
            ></path>
            <path
              d="M16.8149037,20.4902525 L16.8149037,22.8440562 L20.3271699,22.8440562 L20.3271699,20.4902525 C21.0565506,19.9405828 21.4979253,19.0759799 21.4979253,18.1610346 C21.4979253,16.5471482 20.1849231,15.234146 18.5710368,15.234146 C16.9571505,15.234146 15.6441483,16.5471482 15.6441483,18.1610346 C15.6441483,19.0759799 16.0855231,19.9411682 16.8149037,20.4902525 Z"
              id="Path"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
            <path
              d="M18.5710368,16.4049015 C19.5392515,16.4049015 20.3271699,17.1928199 20.3271699,18.1610346 C20.3271699,18.786218 19.9981877,19.3511075 19.4473472,19.6718944 L19.1564145,19.8410686 L19.1564145,21.6733008 L17.9856591,21.6733008 L17.9856591,19.8410686 L17.6947264,19.6718944 C17.143886,19.3511075 16.8149037,18.786218 16.8149037,18.1610346 C16.8149037,17.1928199 17.6028221,16.4049015 18.5710368,16.4049015 Z"
              id="Path"
              fill="#ECD8B6"
              fillRule="nonzero"
            ></path>
            <path
              d="M1,27.9314995 L1,22.2524995 C4.1367251,22.2524995 6.67954321,24.7953176 6.67954321,27.9320427 L1,27.9314995 Z"
              id="Path"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#EE9841"
              strokeLinejoin="round"
            ></path>
            <rect
              id="Rectangle-Copy-2"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#EE9841"
              strokeLinejoin="round"
              transform="translate(34.163806, 18.136449) scale(-1, 1) translate(-34.163806, -18.136449) "
              x="32.3638057"
              y="8.3408553"
              width="3.6"
              height="19.5911874"
            ></rect>
            <path
              d="M35.9638057,27.9314995 L35.9638057,22.2524995 C32.8270806,22.2524995 30.2842625,24.7953176 30.2842625,27.9320427 L35.9638057,27.9314995 Z"
              id="Path"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#EE9841"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
