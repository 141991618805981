import React from 'react';

export default function Hamburger() {
  return (
    <svg
      width="20px"
      height="16px"
      viewBox="0 0 20 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mobile"
          transform="translate(-20.000000, -22.000000)"
          fill="#45241C"
          fillRule="nonzero"
        >
          <path
            d="M20,23.9829688 L20,22.4829688 L40,22.4829688 L40,23.9829688 L20,23.9829688 Z M20,30.75 L20,29.25 L40,29.25 L40,30.75 L20,30.75 Z M20,37.5170312 L20,36.0170312 L40,36.0170312 L40,37.5170312 L20,37.5170312 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
