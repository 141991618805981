import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Diamant from '../../common/icons/Diamant';
import Poop from '../../common/icons/Poop';
import ChevronDown from '../../common/icons/ChevronDown';
import { useSpring, animated } from 'react-spring';

export default function JournalItem(props) {
  const { journalItem, expanded, setExpanded } = props;
  const { correct, correct_answer, given_answer, name, question } = journalItem;
  const [display, setDisplay] = useState('none');
  const upsideDown = useSpring({
    transform: expanded ? 'rotateX(180deg)' : 'rotateX(0deg)',
  });
  const expandAnimation = useSpring({
    display,
    flexDirection: 'column',
    opacity: expanded ? 1 : 0,
    height: expanded ? (correct ? '50px' : '100px') : '0px',
    onRest: () => {
      if (!expanded) {
        setDisplay('none');
      }
    },
    onStart: () => {
      if (expanded) {
        setDisplay('flex');
      }
    },
  });
  const renderIcon = () => {
    if (correct) return <Diamant fillColor={'#F7F3DA'} />;
    return <Poop />;
  };

  return (
    <Wrapper
      onClick={() => (expanded ? setExpanded('') : setExpanded(question))}
    >
      <IconWrapper correct={correct}>{renderIcon()}</IconWrapper>
      <Content correct={correct}>
        <TextContent>
          <h3>{name}</h3>
          <div>{question}</div>
          <animated.div
            style={{
              ...{ marginTop: 12 },
              ...expandAnimation,
            }}
          >
            {!correct ? (
              <div style={{ flex: 1 }}>
                <h3>Dit svar:</h3>
                {given_answer}
              </div>
            ) : null}
            <div style={{ flex: 1 }}>
              <h3>Rigtigt svar:</h3>
              {correct_answer}
            </div>
          </animated.div>
        </TextContent>
        <animated.div
          style={{
            ...{
              height: 10,
              marginTop: 25,
              paddingRight: 15,
            },
            ...upsideDown,
          }}
        >
          <ChevronDown />
        </animated.div>
      </Content>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  margin-bottom: 3px;
  cursor: pointer;
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 28px;
  min-width: 48px;
  margin-right: 2px;
  background-color: #ff768548;
  ${props =>
    props.correct &&
    css`
      background-color: #90c79a48;
    `};
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  background-color: #ff768548;
  ${props =>
    props.correct &&
    css`
      background-color: #90c79a48;
    `};
`;

const TextContent = styled.div`
  flex: 1;
  padding: 12px;
  h3 {
    margin: 0 0 6px 0;
  }
`;
