import treasureIcon from '../../assets/svg/treasure.svg';
import treasureCorrectIcon from '../../assets/svg/treasure-correct.svg';
import treasureInCorrectIcon from '../../assets/svg/treasure-incorrect.svg';
import treasureNearbyIcon from '../../assets/svg/treasure-nearby.svg';
import playerIcon from '../../assets/svg/user.svg';


export const TreasuresSourceId = 'treasures-source';
export const TreasuresSource = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
};
export const TreasuresLayerId = 'treasures-layer';
export const addTreasuresLayer = map => {
  let image = new Image(72, 70);
  image.onload = () => map.addImage('treasure-icon', image);
  image.src = treasureIcon;
  map.addLayer({
    id: TreasuresLayerId,
    source: 'treasures-source',
    type: 'symbol',
    layout: {
      'icon-image': 'treasure-icon',
      'icon-size': ["interpolate",
      ["exponential", 0.8],
      ["zoom"],
      14,
      0.2,
      16,
      0.7],
      'icon-allow-overlap': true,
    },
    filter: ['all', ['==', ['get', 'found'], false]],
  });
};
export const getTreasuresLayerFilter = nearbyTreasureId => {
  return [
    'all',
    ['==', ['get', 'found'], false],
    ['match', ['get', 'id'], nearbyTreasureId, false, true],
  ];
};
export const addTreasuresCorrectLayer = map => {
  let image = new Image(72, 70);
  image.onload = () => map.addImage('treasure-correct-icon', image);
  image.src = treasureCorrectIcon;
  map.addLayer({
    id: 'treasures-correct-layer',
    source: 'treasures-source',
    type: 'symbol',
    layout: {
      'icon-image': 'treasure-correct-icon',
      'icon-size': ["interpolate",
      ["exponential", 0.8],
      ["zoom"],
      14,
      0.2,
      16,
      0.7],
      'icon-allow-overlap': true,
    },
    filter: ['==', ['get', 'correct'], true],
  });
};
export const addTreasuresInCorrectLayer = map => {
  let image = new Image(72, 70);
  image.onload = () => map.addImage('treasure-incorrect-icon', image);
  image.src = treasureInCorrectIcon;
  map.addLayer({
    id: 'incorrect-treasures-layer',
    source: 'treasures-source',
    type: 'symbol',
    layout: {
      'icon-image': 'treasure-incorrect-icon',
      'icon-size': ["interpolate",
      ["exponential", 0.8],
      ["zoom"],
      14,
      0.2,
      16,
      0.7],
      'icon-allow-overlap': true,
    },
    filter: ['==', ['get', 'correct'], false],
  });
};

export const NearbyTreasuresLayerId = 'nearby-treasures-layer';
export const addTreasuresNearbyLayer = map => {
  let image = new Image(72, 70);
  image.onload = () => map.addImage('treasure-nearby-icon', image);
  image.src = treasureNearbyIcon;
  map.addLayer({
    id: NearbyTreasuresLayerId,
    source: 'treasures-source',
    type: 'symbol',
    layout: {
      'icon-image': 'treasure-nearby-icon',
      'icon-size': ["interpolate",
      ["exponential", 0.8],
      ["zoom"],
      14,
      0.2,
      16,
      0.7],
      'icon-allow-overlap': true,
    },
    filter: ['==', ['get', 'id'], -1],
  });
};
export const getNearbyTreasuresLayerFilter = nearbyTreasureId => {
  return ['all', ['match', ['get', 'id'], nearbyTreasureId, true, false]];
};

export const getTreasureGeoJSON = treasures => {
  return {
    type: 'FeatureCollection',
    features: treasures.map(t => {
      const { id, lat, lng, located, name } = t;
      let properties = {
        id,
        found: located === 'Not found' ? false : true,
        name,
        lng,
        lat,
      };
      if (located !== 'Not found') {
        properties.correct = located === 'Correct' ? true : false;
      }
      return {
        type: 'Feature',
        properties,
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
      };
    }),
  };
};



// Other players

export const PlayerSourceId = 'player-source';
export const PlayerSource = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
};

export const PlayerLayerId = 'player-layer';
export const addPlayerLayer = map => {
  let image = new Image(72, 70);
  image.onload = () => map.addImage('player-marker', image);
  image.src = playerIcon;
  map.addLayer({
    id: PlayerLayerId,
    source: 'player-source',
    type: 'symbol',
    layout: {
      'icon-image': 'player-marker',
      'icon-size': ["interpolate",
      ["exponential", 0.8],
      ["zoom"],
      13,
      0.2,
      16,
      0.7],
      'icon-allow-overlap': true,
    },
  });
};


export const getPlayerGeoJSON = player => {
  return {
    type: 'FeatureCollection',
    features: player.map(t => {
      const { id, lt, lg } = t;
      let properties = {
        id,
        lg,
        lt,
      };
      return {
        type: 'Feature',
        properties,
        geometry: {
          type: 'Point',
          coordinates: [lg, lt],
        },
      };
    }),
  };
};
