import React from 'react';
import styled from 'styled-components';

export default function ModalHeader(props) {
  const { icon, header, subheader } = props;
  return (
    <Header>
      <IconWrapper>{icon}</IconWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          marginLeft: 12,
        }}
      >
        <h2>{header}</h2>
        {subheader}
      </div>
    </Header>
  );
}
const Header = styled.div`
  display: flex;
  height: 50px;
  h2 {
    font-size: 19px;
    margin: 0;
  }
`;
const IconWrapper = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #eee3c6;
  display: flex;
  align-items: center;
  justify-content: center;
`;
