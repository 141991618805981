import React, { Component } from 'react';
import {
  GeolocateControl,
  Map,
  AttributionControl,
  NavigationControl,
} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './mapbox-gl-overrides.css';
import { getFromDB } from '../../api/DatabaseCalls.js';
import { whereInArray, removeFromArray } from '../../utility/DataManagement.js';
//This if for establishing connection to the websocket server

import socketIOClient from 'socket.io-client';
import { getNearbyTreasureId } from '../../utility/map.js';
import TreasureFound from './Question/TreasureFound';
import Question from './Question/Question';
import Score from './Highscore/Score';
import Journal from './Journal/Journal';
import NoConnection from './NoConnection';
import '../../styles.css';
import Toast from './Toast';
import MapButton from './MapButton.js';
import MenuButton from './MenuButton.js';
import Diamant from '../common/icons/Diamant.js';
import Trophy from '../common/icons/Trophy.js';
import TreasureDiamond from '../common/icons/TreasureDiamond';
import TreasurePoop from '../common/icons/TreasurePoop';
import Pirat from '../common/icons/Pirat';
import {
  TreasuresSource,
  TreasuresSourceId,
  TreasuresLayerId,
  NearbyTreasuresLayerId,
  addTreasuresLayer,
  addTreasuresCorrectLayer,
  addTreasuresInCorrectLayer,
  addTreasuresNearbyLayer,
  getTreasureGeoJSON,
  getTreasuresLayerFilter,
  getNearbyTreasuresLayerFilter,
  PlayerSource,
  PlayerSourceId,
  PlayerLayerId,
  addPlayerLayer,
  getPlayerGeoJSON,
} from './MapLayers';
import MapItemClicked from './Question/MapItemClicked';
import Menu from './Menu/Menu';
import TreasureIcon from './Question/TreasureIcon';
import { text } from '../common/text'

// Google Analytics
import ReactGA from 'react-ga';



class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otherPlayers: [],
      nearbyTreasure: -1, //This is the index of a found treasure in the treasure array
      showPopup: '',
      remainingTreasures: [],
      username: '', // To be set by the PrivateRoute middleware
      userid: '', // To be set by the PrivateRoute middleware
      toast: {
        show: false,
        content: null,
        icon: null,
        color: 'yellow',
      },
      mapItemClicked: '',
      preOpenJournalItem: '',
    };

    this.socket = socketIOClient(process.env.REACT_APP_SERVER_URL); //Establish connection to the socket
    ReactGA.event({ category: 'Socket', action: 'ConnectionMade' });
    this.socket.on('reconnect', (attemptNumber) => {
      ReactGA.event({ category: 'Socket', action: 'ReconnectionMade' });
    });
  }

  hideToast() {
    this.setState({
      toast: { ...this.state.toast, show: false },
    });
  }
  /**
   * Display a toast to user
   * @param content String or React component to show in toast
   * @param icon Icon (actually a React component) to display in toast
   * @param color background color of toast
   */
  toast(content, icon, color) {
    this.setState({
      toast: {
        show: true,
        content,
        icon,
        color,
      },
    });
  }
  emitThis(emittype, content, socket) {
    socket.emit(emittype, content);
  }
  locationFound(e) {
    const { coords } = e;
    const { latitude, longitude } = coords;
    // { accuracy, altitude, heading, speed, timestamp }

    //Send the location of the player to all other players
    this.emitThis(
      'position',
      {
        lat: latitude,
        lng: longitude,
      },
      this.socket
    );
    //Checks if a treasure is near the user - returns an index of where the treasure is in the array
    var nearbytreasure = getNearbyTreasureId(
      this.state.remainingTreasures,
      [longitude, latitude],
      0.02
    );
    // Notes down which treasure is nearby - (used for giving the correct questions)
    this.setState({
      nearbyTreasure: nearbytreasure,
    });
  }
  onMapClick(e) {
    const clickedTreasures = this.map.queryRenderedFeatures(e.point, {
      layers: [
        TreasuresLayerId,
        'treasures-correct-layer',
        'incorrect-treasures-layer',
      ],
    });
    if (clickedTreasures.length) {
      if (clickedTreasures[0].properties.found) {
        ReactGA.event({
          category: 'Map',
          action: 'FoundTreasureClicked'
        })
        this.setState({
          showPopup: 'Journal',
          preOpenJournalItem: clickedTreasures[0].properties.name,
        });
      } else {
        if (!this.state.mapItemClicked) {
          ReactGA.event({
            category: 'Map',
            action: 'UnfoundTreasureClicked'
          })
          this.setState({
            mapItemClicked: clickedTreasures[0].properties.name,
          });
        } else {
          ReactGA.event({
            category: 'Map',
            action: 'NewUnfoundTreasureClicked'
          })
          this.setState({ mapItemClicked: '' });
          setTimeout(() => {
            this.setState({
              mapItemClicked: clickedTreasures[0].properties.name,
            });
          }, 200);
        }
      }
    } else {
      this.setState({ mapItemClicked: '' });


      // This gives an response on clicking other players - it is with
      const clickedPlayers = this.map.queryRenderedFeatures(e.point, {
        layers: [PlayerLayerId]
      });

      if (clickedPlayers.length) {
        setTimeout(() => {
          this.setState({
            mapItemClicked: 'PlayerClicked',
          });
        }, 200);
      }
    }


  }
  onMapLoad() {
    this.map.addSource('hegn', {
      type: 'geojson',
      data: 'https://raw.githubusercontent.com/AsgerPetersen/derundehaver/master/data/hegn.geojson'
    });

    this.map.addLayer({
      'id': 'hegn',
      'type': 'line',
      'source': 'hegn',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': '#8a8',
        'line-width': 4
      }
    });

    this.map.addSource(TreasuresSourceId, TreasuresSource);
    this.map.addSource(PlayerSourceId, PlayerSource);
    addTreasuresInCorrectLayer(this.map);
    addTreasuresCorrectLayer(this.map);
    addTreasuresLayer(this.map);
    addTreasuresNearbyLayer(this.map);
    addPlayerLayer(this.map);
    this.treasureFound().then(() => {

      //This checks if there is GPS signal is blocked
      navigator.geolocation.getCurrentPosition((position) => {
        this.geoLocateControl.trigger();
      }, (error) => {
        //If the error="User denied Geolocation"
        if (error.code === 1) {
          this.setState({ showPopup: 'noConnection' })
        }
      })

    });
    this.map.on('click', this.onMapClick.bind(this));

    this.map.style.sourceCaches[TreasuresSourceId]._source.attribution =
      '&copy; <a href="https://www.septima.dk">Septima</a>';

    // Put socket listeners on, when map is ready
    this.socket.on('treasureCorrect', treasureCorrect => {
      if (treasureCorrect === true) {
        this.toast(text.toastSucces, <TreasureDiamond />, '#E1F7DA');
      } //correct is the name of the collumn in the database with data about the answer being correct or not
      else {
        this.toast(
          text.toastFail,
          <TreasurePoop />,
          '#F9CDC0'
        );
      }
      this.treasureFound();
    });

    this.socket.on('alertToOthers', ({ username, question }) => {
      this.toast(
        <>
          <span style={{ fontWeight: 600 }}>{username}</span>
          <span> har åbnet skatten: {question}</span>
        </>,
        <Pirat />,
        '#D7F1DF'
      );
    });

    this.socket.on('firstTimeUser', ({ username }) => {
      this.toast(
        <>
          <span style={{ fontWeight: 600 }}>{username}</span>
          <span> er kommet ombord.</span>
        </>,
        <Pirat />,
        '#D7F1DF'
      );
    });

    this.socket.on('returningUser', ({ username }) => {
      this.toast(
        <>
          <span style={{ fontWeight: 600 }}>{username}</span>
          <span> er kommet ombord igen.</span>
        </>,
        <Pirat />,
        '#D7F1DF'
      );
    });
    //This function gets an array with the locations of all users from the server and then removes the player himself from the array
    this.socket.on('pPos', playerPositionArray => {
      var index = whereInArray(
        this.state.userid,
        'id',
        playerPositionArray
      ); //Finds the location of the user himself
      var onlyOtherPlayers = removeFromArray(playerPositionArray, index); //Removes the user
      this.map.getSource(PlayerSourceId).setData(getPlayerGeoJSON(onlyOtherPlayers));
      // onlyOtherPlayers = addMarkerToObject(onlyOtherPlayers, 'player-marker');

      this.setState({
        otherPlayers: onlyOtherPlayers,
      });
    });
  }
  //Here the map gets created
  setupMap() {
    // this.layer = L.layerGroup().addTo(this.map);
    this.map = new Map({
      container: this.mapContainer,
      style: `https://api.maptiler.com/maps/87993a04-4daf-4373-9db9-6867c6fe3a5a/style.json?key=V1QwrAoT6R0N6HByveXa`,
      center: [12.56, 55.67],
      zoom: 13,
      minZoom: 7,
      attributionControl: false,
    });

    this.geoLocateControl = new GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    });
    this.geoLocateControl.on('geolocate', this.locationFound.bind(this));
    this.map.addControl(
      new NavigationControl({ showCompass: false }),
      'bottom-right'
    );
    this.map.addControl(this.geoLocateControl, 'bottom-right');
    this.map.addControl(new AttributionControl(), 'bottom-left');
    this.map.on('load', this.onMapLoad.bind(this));
  }

  // Runs once when component is created
  componentDidMount() {
    // If component mounts from a PrivateRoute, it should have had the username set
    if (this.props.username !== undefined) {
      this.setState({
        username: this.props.username,
      });
    }
    if (this.props.userid !== undefined) {
      this.setState({
        userid: this.props.userid,
      });
    }

    this.setupMap();

  }

  // Runs every time props or state changes
  componentDidUpdate(prevProps, prevState) {
    const { remainingTreasures, nearbyTreasure } = this.state;
    //Listen for changes in treasures
    if (prevState.remainingTreasures !== remainingTreasures) {
      if (remainingTreasures.length === 0) {
        this.toast(text.toastAllTreasure);
      }
    }
    if (prevState.nearbyTreasure !== nearbyTreasure) {
      this.map.setFilter(
        TreasuresLayerId,
        getTreasuresLayerFilter(nearbyTreasure)
      );
      this.map.setFilter(
        NearbyTreasuresLayerId,
        getNearbyTreasuresLayerFilter(nearbyTreasure)
      );
    }
  }

  //This function is triggered whenever react change what it should visualising
  //Values that are visualising things are Journal, Score, Menu, Question
  //Any other values will show nothing - The reason why they aren't just called '' or hidden is inorder to enable Google Analytics to track them individually
  showPopup(whatToshow) {
    //This sets the question popup to visible
    this.setState({
      showPopup: whatToshow,
    });

    //Here you can declare which button presses Google Analytics should keep track of
    //Currently tracking everything
    // const trackedEvents = ['Question', 'Menu', 'Journal', 'Score', 'QuestionExit']
    // if (trackedEvents.indexOf(whatToshow) > -1) {
    ReactGA.event({
      category: 'Button',
      action: whatToshow
    });

    // }


  }

  //This function is for determining which treasures are answered correctly, incorrectly and not yet found
  treasureFound() {
    return getFromDB('treasures/')
      .then(response => response.json())
      .then(data => {
        this.map.getSource(TreasuresSourceId).setData(getTreasureGeoJSON(data));
        const remainingTreasures = data.filter(d => d.located === 'Not found');
        this.setState({ remainingTreasures });
      })
      .catch(err => console.log(err));
  }

  //This is the function that is checking if questions are answered correctly or incorrectly - Has to be rewritten after question rendering gets fixed
  onAnswer(answer) {
    var string = '{"optionid":' + answer + '}';
    this.emitThis('TreasureAnswered', JSON.parse(string), this.socket);
    this.showPopup('Answered');

    //This ensures that the ready-to-answer-popup disappear
    this.setState({
      nearbyTreasure: -1,
    });
  }

  render() {
    //This adds the map and the questionPopup (which is invisible by default)
    const {
      show: showToast,
      content: toastContent,
      icon,
      color: toastColor,
    } = this.state.toast;
    const { nearbyTreasure, mapItemClicked } = this.state;
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
        }}
      >
        <Toast
          show={showToast}
          content={toastContent}
          color={toastColor}
          icon={icon}
          hide={this.hideToast.bind(this)}
        />
        <TreasureFound
          show={nearbyTreasure > -1 && !showToast}
          onYesClick={this.showPopup.bind(this)}
        />
        <MapItemClicked
          show={mapItemClicked.length && !showToast}
          titelOfObject={mapItemClicked ? (mapItemClicked === 'PlayerClicked') ? 'Yarrrrrrh!' : mapItemClicked : ''}
          textcontent={mapItemClicked ? (mapItemClicked === 'PlayerClicked') ? text.playerClicked : text.treasureClicked : ''}
          icon={mapItemClicked ? (mapItemClicked === 'PlayerClicked') ? <Pirat fill={'#D89474'} /> : <TreasureIcon fill={'#D89474'} /> : null}
          close={() => this.setState({ mapItemClicked: '' })}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
          ref={el => (this.mapContainer = el)}
        />
        <Question
          show={this.state.showPopup === 'Question'}
          showPopup={this.showPopup.bind(this)}
          treasure={this.state.remainingTreasures.find(
            t => t.id === nearbyTreasure
          )}
          onAnswer={this.onAnswer.bind(this)}
        />
        <Score
          userid={this.state.userid}
          show={this.state.showPopup === 'Score'}
          onClose={this.showPopup.bind(this)}
        />
        <Journal
          show={this.state.showPopup === 'Journal'}
          onClose={this.showPopup.bind(this)}
          preOpenJournalItem={this.state.preOpenJournalItem}
        />
        <Menu
          show={this.state.showPopup === 'Menu'}
          onClose={this.showPopup.bind(this)}
          history={this.props.history}
          socket={this.socket}
        />
        <NoConnection
          show={this.state.showPopup === 'noConnection'}
          onClose={this.showPopup.bind(this)}
        />
        <div />
        <MenuButton
          onClick={() => {
            this.showPopup('Menu');
          }}
        />
        <div
          style={{
            position: 'absolute',
            zIndex: 5,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-evenly',
            backgroundColor: '#faf6e0',
            boxShadow: '0 2px 15px 0 rgba(69,36,28,0.40)',
          }}
        >
          <MapButton
            onClick={() => {
              this.showPopup(this.state.showPopup === 'Journal' ? 'JournalExitMapButton' : 'Journal');
              this.setState({
                preOpenJournalItem: '',
              });
            }}
          >
            <Diamant />
            <span>Mine skatte</span>
          </MapButton>
          <div
            style={{
              alignSelf: 'stretch',
              width: 1,
              borderRight: 'solid 1px #45241c21',
            }}
          ></div>
          <MapButton onClick={this.state.showPopup === 'Score' ? this.showPopup.bind(this, 'ScoreExitMapButton') : this.showPopup.bind(this, 'Score')}>
            <Trophy />
            <span>Highscores</span>
          </MapButton>
        </div>
      </div>
    );
  }
}

export default MapContainer;
