import React from 'react';

export default function TreasurePoop() {
  return (
    <svg
      width="37px"
      height="50px"
      viewBox="0 0 37 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Mobile" transform="translate(-35.000000, -20.000000)">
          <g id="skat" transform="translate(35.000000, 20.000000)">
            <rect
              id="Rectangle"
              fill="#E86969"
              x="0.963805662"
              y="30.3558339"
              width="35"
              height="18.5762088"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#E86969"
              strokeLinejoin="round"
              x="3.03922547"
              y="14.4849826"
              width="31.0636227"
              height="15.8708513"
            ></rect>
            <rect
              id="Rectangle-Copy"
              stroke="#45241C"
              strokeWidth="1.8"
              strokeLinejoin="round"
              x="15.4712343"
              y="12"
              width="6.19960501"
              height="2.48498259"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#E86969"
              strokeLinejoin="round"
              x="1"
              y="29.3408553"
              width="3.6"
              height="19.5911874"
            ></rect>
            <path
              d="M4.6,33.4394484 L33.1240341,33.4394484"
              id="Path-6"
              stroke="#45241C"
              strokeWidth="1.8"
            ></path>
            <path
              d="M4.6,48.9320427 L33.1240341,48.9320427"
              id="Path-6"
              stroke="#45241C"
              strokeWidth="1.8"
            ></path>
            <path
              d="M18.5710368,36.234146 C20.1849231,36.234146 21.4979253,37.5471482 21.4979253,39.1610346 C21.4979253,40.0759799 21.0565506,40.9405828 20.3271699,41.4902525 L20.3271699,41.4902525 L20.3271699,43.8440562 L16.8149037,43.8440562 L16.8149037,41.4902525 C16.0855231,40.9411682 15.6441483,40.0759799 15.6441483,39.1610346 C15.6441483,37.5471482 16.9571505,36.234146 18.5710368,36.234146 Z M18.5710368,37.4049015 C17.6028221,37.4049015 16.8149037,38.1928199 16.8149037,39.1610346 C16.8149037,39.786218 17.143886,40.3511075 17.6947264,40.6718944 L17.6947264,40.6718944 L17.9856591,40.8410686 L17.9856591,42.6733008 L19.1564145,42.6733008 L19.1564145,40.8410686 L19.4473472,40.6718944 C19.9981877,40.3511075 20.3271699,39.786218 20.3271699,39.1610346 C20.3271699,38.1928199 19.5392515,37.4049015 18.5710368,37.4049015 Z"
              id="Combined-Shape"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
            <path
              d="M1,48.9314995 L1,43.2524995 C4.1367251,43.2524995 6.67954321,45.7953176 6.67954321,48.9320427 L1,48.9314995 Z"
              id="Path"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#E86969"
              strokeLinejoin="round"
            ></path>
            <rect
              id="Rectangle-Copy-2"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#E86969"
              strokeLinejoin="round"
              transform="translate(34.163806, 39.136449) scale(-1, 1) translate(-34.163806, -39.136449) "
              x="32.3638057"
              y="29.3408553"
              width="3.6"
              height="19.5911874"
            ></rect>
            <path
              d="M35.9638057,48.9314995 L35.9638057,43.2524995 C32.8270806,43.2524995 30.2842625,45.7953176 30.2842625,48.9320427 L35.9638057,48.9314995 Z"
              id="Path"
              stroke="#45241C"
              strokeWidth="1.8"
              fill="#E86969"
              strokeLinejoin="round"
            ></path>
            <g
              id="Lort"
              transform="translate(10.398550, 17.362200)"
              fillRule="nonzero"
            >
              <path
                d="M14.43975,8.66799545 C14.5220401,8.45515484 14.5721566,8.22560877 14.5721566,7.98368816 C14.5721566,6.93588128 13.7192474,6.08297208 12.6714405,6.08297208 L12.6200865,6.08297208 C12.4514846,4.93307599 11.8377117,3.87382276 10.8969439,3.16786014 L10.8341435,3.12114655 C10.316582,2.73258871 9.93452074,2.20234341 9.72910481,1.5876424 L9.20009695,0 L6.1290669,2.19337195 C4.84490795,3.11031897 4.01860387,4.53276238 3.84010231,6.08297208 L3.80143216,6.08297208 C2.75362528,6.08297208 1.90071608,6.93588128 1.90071608,7.98368816 C1.90071608,8.22560877 1.95083258,8.45515484 2.03312274,8.66799545 C0.874873874,8.90125394 5.86197757e-14,9.92554928 5.86197757e-14,11.1515483 C5.86197757e-14,12.5492429 1.13659353,13.6858364 2.53428811,13.6858364 L13.9385846,13.6858364 C15.3362792,13.6858364 16.4728727,12.5492429 16.4728727,11.1515483 C16.4728727,9.92554928 15.5979988,8.90125394 14.43975,8.66799545 L14.43975,8.66799545 Z"
                id="Path"
                fill="#45241C"
              ></path>
              <path
                d="M13.9385846,12.4186924 L2.53428811,12.4186924 C1.83544082,12.4186924 1.26714405,11.8503956 1.26714405,11.1515483 C1.26714405,10.452701 1.83544082,9.88440424 2.53428811,9.88440424 L10.1371524,9.88440424 L10.1371524,8.61726019 L3.80143216,8.61726019 C3.45247257,8.61726019 3.16786014,8.33326644 3.16786014,7.98368816 C3.16786014,7.63410988 3.45247257,7.35011613 3.80143216,7.35011613 L6.9692923,7.35011613 L6.9692923,6.08297208 L5.12116761,6.08297208 C5.29224449,4.9432849 5.91437017,3.90414013 6.86534689,3.22416394 L8.54053857,2.02817295 C8.83071947,2.87149201 9.35972741,3.59880047 10.0743521,4.13461439 L10.1371524,4.182256 C10.7586594,4.64846356 11.1800095,5.33215208 11.3353089,6.08297208 L9.50358041,6.08297208 L9.50358041,7.35011613 L12.6714405,7.35011613 C13.0204001,7.35011613 13.3050126,7.63410988 13.3050126,7.98368816 C13.3050126,8.33326644 13.0204001,8.61726019 12.6714405,8.61726019 L11.4042965,8.61726019 L11.4042965,9.88440424 L13.9385846,9.88440424 C14.6374319,9.88440424 15.2057287,10.452701 15.2057287,11.1515483 C15.2057287,11.8503956 14.6374319,12.4186924 13.9385846,12.4186924 Z"
                id="Path"
                fill="#D29687"
              ></path>
            </g>
            <path
              d="M27.0402201,8.41901059 C26.7951357,8.84366167 26.9407304,9.38617518 27.3650348,9.63125946 C27.5047364,9.7120299 27.6572641,9.75050842 27.8080586,9.75050842 C28.1145007,9.75050842 28.4129697,9.59139432 28.5772837,9.30679136 C29.343389,7.98014672 28.9260176,6.97138365 28.6493877,6.30372817 C28.4084633,5.72134952 28.3432923,5.51786362 28.5772837,5.11262516 C28.8223681,4.68797408 28.67712,4.14546057 28.2524689,3.90037629 C27.8278179,3.65529192 27.2853044,3.80053995 27.0402201,4.22519103 C26.3580051,5.40728098 26.7500707,6.35503295 27.0097145,6.98247658 C27.2489057,7.56000212 27.368848,7.85015146 27.0402201,8.41901059 Z"
              id="Path"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
            <path
              d="M17.732282,4.8709456 C17.4871976,5.29559668 17.6327923,5.83811019 18.0570968,6.08319447 C18.1967983,6.16396491 18.3493261,6.20244344 18.5001206,6.20244344 C18.8065627,6.20244344 19.1050317,6.04332934 19.2693456,5.75872637 C20.0354509,4.43208173 19.6180796,3.42331866 19.3414497,2.75566318 C19.1005252,2.17328453 19.0353543,1.96979863 19.2693456,1.56456018 C19.51443,1.1399091 19.369182,0.597395583 18.9445309,0.352311301 C18.5198798,0.107226929 17.9773664,0.25247496 17.732282,0.677126039 C17.050067,1.859216 17.4421327,2.80696797 17.7017765,3.43441159 C17.9409677,4.01193713 18.06091,4.30208647 17.732282,4.8709456 Z"
              id="Path"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
            <path
              d="M8.42434397,10.2030523 C8.1792596,10.6277034 8.32485426,11.1702169 8.74915871,11.4153012 C8.88886028,11.4960716 9.04138802,11.5345501 9.19218252,11.5345501 C9.49862462,11.5345501 9.79709365,11.375436 9.96140759,11.0908331 C10.7275129,9.76418841 10.3101415,8.75542535 10.0335116,8.08776987 C9.79258717,7.50539121 9.72741623,7.30190532 9.96140759,6.89666686 C10.206492,6.47201578 10.0612439,5.92950227 9.63659285,5.68441799 C9.21194177,5.43933361 8.66942834,5.58458164 8.42434397,6.00923272 C7.74212898,7.19132268 8.13419463,8.13907465 8.39383842,8.76651827 C8.63302961,9.34404382 8.75297193,9.63419316 8.42434397,10.2030523 Z"
              id="Path"
              fill="#45241C"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
