import distance from '@turf/distance';
// import  { Marker } from 'mapbox-gl';
import { Marker } from 'mapbox-gl';
//Makes a font awesome icon
export function makeIcon(iconName, color) {
  // return L.AwesomeMarkers.icon({
  //   icon: iconName,
  //   prefix: 'fa',
  //   markerColor: color
  // });
}

//Removes all markers from a layer and then redraws the markers
export function redrawMarkers(previousMarkers, newMarkers, map) {
  previousMarkers.forEach(m => {
    if (m) m.remove();
  });
  newMarkers.forEach(m => {
    m.addTo(map);
  });
}

/**
 * Returns id of closest treasure, or -1 if there are no treasures closer than threshold.
 * @param remainingTreasures array of treasures
 * @param currentPosition users current position
 * @param threshold threshold for search distance, in kilometers
 * @returns id of closest treasure, or -1
 */
export function getNearbyTreasureId(
  remainingTreasures,
  currentPosition,
  threshold
) {
  let response = -1;
  let currentClosestDistance = threshold;
  for (var i = 0; i < remainingTreasures.length; i++) {
    const to = [remainingTreasures[i].lng, remainingTreasures[i].lat];
    const distanceToTreasure = distance(currentPosition, to);
    if (distanceToTreasure < currentClosestDistance) {
      //100 is the current search distance. So you have to be within 100 meter of a treasure to find it
      response = remainingTreasures[i].id;
      currentClosestDistance = distanceToTreasure;
    }
  }
  return response;
}

/**
 * Add a mapbox-gl-js Marker to an object or array
 * @param object object or array of objects to add markers to.
 * @param className css classname given to marker. this decides which icon ot gets.
 * @returns object or array of objects with markers added
 */
export function addMarkerToObject(object, className) {
  if (Array.isArray(object)) {
    //This first part of the function is for handling an array of objects
    object.map(content => {
      const { lng, lat } = content;
      if (!content.marker) {
        var element = document.createElement('div');
        element.className = className;
        content.marker = new Marker(element).setLngLat([lng, lat]);
      }
      return content;
    });
  } else {
    //This part is for handeling a single object
    const { lng, lat } = object;
    var element = document.createElement('div');
    element.className = className;
    object.marker = new Marker(element).setLngLat([lng, lat]);
  }
  return object;
}
